import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { updateUser } from 'app/modules/users'
import Spinner from 'app/components/Spinner'
import { Helmet } from 'react-helmet'
import PlacesAutocomplete from 'react-places-autocomplete'
import { geocodeByPlaceId } from 'react-places-autocomplete'
import { getTitle } from 'app/helpers/app'
import { toast } from 'react-toastify'
import { parse } from 'query-string'
import styled from 'styled-components'
import colors from 'app/config/colors'
import config from 'app/config'
import { t } from 'app/locales'

const Location = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [text, setText] = useState('')
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [loading, setLoading] = useState(false)
  const { user } = useSelector(state => state.app)

  useEffect(() => {
    if (navigator.geolocation) {
      //setLoading(true)
      navigator.geolocation.getCurrentPosition(async location => {
        setLatitude(location.coords.latitude)
        setLongitude(location.coords.longitude)

        // Get reverse geocode
        const coords = location.coords
        const { results } = await getReverseGeocode(
          coords.latitude,
          coords.longitude
        )

        // Get address
        const components = results.find(result =>
          result.types.includes('locality')
        )
        if (components && components.address_components) {
          const address = getAddress(components.address_components)
          setCity(address.city)
          setState(address.state)
          setCountry(address.country)
          setText(address.city + ', ' + address.state)
        }

        setLoading(false)
      })
    }
  }, [])

  const getReverseGeocode = (lat, lng) => {
    const url = 'https://maps.googleapis.com/maps/api/geocode/json'
    return fetch(
      `${url}?address=${lat},${lng}&key=${config.googleApiKey}`
    ).then(res => res.json())
  }

  const getAddress = components => {
    try {
      const level = 'administrative_area_level'
      const data = {
        country: components.find(component =>
          component.types.includes(`country`)
        ),
        state: components.find(component =>
          component.types.includes(`${level}_1`)
        ),
        locality: components.find(component =>
          component.types.includes(`locality`)
        ),
        adm2: components.find(component =>
          component.types.includes(`${level}_2`)
        )
      }
      const country = data.country ? data.country.long_name : null
      const state = data.state ? data.state.short_name : null
      const city = data.locality ? data.locality.long_name : data.adm2.long_name
      return { country, state, city }
    } catch (err) {
      return false
    }
  }

  const handleSelect = async (addressText, placeId) => {
    const results = await geocodeByPlaceId(placeId)
    const components = results.find(result => result.types.includes('locality'))
    if (components && components.address_components) {
      const address = getAddress(components.address_components)
      setCity(address.city)
      setState(address.state)
      setCountry(address.country)
      setText(address.city + ', ' + address.state)
    }
  }

  const handleSubmit = async () => {
    if (!city || !state || !country) {
      toast(t('location.fail'))
      return false
    }
    await dispatch(
      updateUser({
        ...user,
        location: { coordinates: [longitude, latitude] },
        city,
        state,
        country
      })
    )
    if (user.profile_picture) {
      const params = parse(location.search)
      if (Boolean(params.url)) {
        history.push(params.url)
      } else {
        history.push('/feed')
      }
    } else {
      history.push(`/picture${location.search}`)
    }
  }

  return (
    <LocationWrapper>
      <Helmet title={getTitle('location')} />
      <LocationContent>
        <Logo>
          <LogoImage src={require('app/assets/img/logo.png')} />
        </Logo>
        <Form>
          <FormGroup>
            <Subtitle>{t('location.subtitle')}</Subtitle>
            <Description>{t('location.description')}</Description>
          </FormGroup>
          <FormGroup>
            <PlacesAutocomplete
              value={text}
              onChange={value => setText(value)}
              onSelect={handleSelect}
              searchOptions={{ types: ['(cities)'] }}
            >
              {props => (
                <PlacesContainer>
                  <Input
                    {...props.getInputProps()}
                    placeholder={t('location.city')}
                  />
                  <PlacesSuggestions>
                    {props.suggestions.map(suggestion => (
                      <Suggestion {...props.getSuggestionItemProps(suggestion)}>
                        {suggestion.description}
                      </Suggestion>
                    ))}
                  </PlacesSuggestions>
                </PlacesContainer>
              )}
            </PlacesAutocomplete>
          </FormGroup>
          <FormGroup>
            <Button onClick={handleSubmit}>{t('location.save')}</Button>
          </FormGroup>
        </Form>
      </LocationContent>
      {loading && <Spinner />}
    </LocationWrapper>
  )
}

const LocationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 40px);
`
const LocationContent = styled.div`
  width: 320px;
  justify-content: center;
  padding-bottom: 20px;
`
const Logo = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-bottom: 30px;
`
const LogoImage = styled.img`
  width: 100%;
`
const Subtitle = styled.div`
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  color: ${colors.black1};
  margin-bottom: 10px;
  text-align: center;
`
const Description = styled.div`
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.gray2};
  margin-bottom: 25px;
  text-align: center;
`
const Form = styled.div`
  display: flex;
  flex-direction: column;
`
const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`
const PlacesContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const PlacesSuggestions = styled.div`
  display: flex;
  flex-direction: column;
`
const Suggestion = styled.button`
  width: 100%;
  text-align: left;
  font-family: OpenSans;
  font-size: 14px;
  border: 0;
  background: 0;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
`
const Input = styled.input`
  font-family: OpenSans;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  font-size: 14px;
  text-align: left;
  margin-bottom: 5px;
  height: 42px;
  box-sizing: border-box;
  color: ${colors.gray2};
  background: ${colors.white1};
  border: 1px solid ${props => (props.error ? colors.red1 : colors.gray1)};
`
const Button = styled.button`
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.white1};
  background: ${colors.praticantes1};
  border: 0;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
`

export default memo(Location)
