import React, { memo, useState } from 'react'
import { useHistory } from 'react-router'
import AsyncStorage from '@react-native-community/async-storage'
import { setUser, logout } from 'app/modules/app'
import { updateUser, deleteUser } from 'app/modules/users'
import { useSelector, useDispatch } from 'react-redux'
import PlacesAutocomplete from 'react-places-autocomplete'
import { geocodeByPlaceId } from 'react-places-autocomplete'
import DatePicker from 'react-datepicker'
import Content from 'app/components/Content'
import styled from 'styled-components'
import Spinner from 'app/components/Spinner'
import ModalActions from 'app/components/ModalActions'
import { Link } from 'react-router-dom'
import { parseLocation } from 'app/helpers/geocode'
import colors from 'app/config/colors'
import { t } from 'app/locales'

const ProfileEdit = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [options, setOptions] = useState([])
  const { user } = useSelector(state => state.app)
  const [name, setName] = useState(user.name)
  const [city, setCity] = useState(user.city)
  const [cityText, setCityText] = useState(`${user.city}, ${user.state}`)
  const [state, setState] = useState(user.state)
  const [country, setCountry] = useState(user.country)
  const [birthday, setBirthday] = useState(user.birthday)
  const [sex, setSex] = useState(user.sex)
  const [church, setChurch] = useState(user.church)
  const [bio, setBio] = useState(user.bio)
  const [testimony, setTestimony] = useState(user.testimony)
  const [website, setWebsite] = useState(user.website)
  const [loading, setLoading] = useState(false)

  const handleSelectCity = async (text, placeId) => {
    const results = await geocodeByPlaceId(placeId)
    const location = results.find(result => result.types.includes('locality'))
    const address = parseLocation(location)
    if (Boolean(address)) {
      setCity(address.city)
      setState(address.state)
      setCountry(address.country)
      setCityText(`${address.city}, ${address.state}`)
    }
  }

  const handleDelete = async e => {
    e.preventDefault()
    setOptions([
      {
        title: t('actions.deleteAccountYes'),
        action: async () => {
          setOptions([])
          setModal(false)
          setLoading(true)
          await dispatch(deleteUser(user.id))
          await dispatch(logout())
          await AsyncStorage.clear()
          setLoading(false)
          history.push('/signup')
        }
      },
      {
        title: t('actions.deleteAccountNo'),
        action: () => {
          setOptions([])
          setModal(false)
        }
      }
    ])
    setModal(true)
  }

  const handleSave = async e => {
    e.preventDefault()
    setLoading(true)
    const updated = {
      ...user,
      name,
      city,
      state,
      country,
      birthday,
      sex,
      church,
      bio,
      testimony,
      website
    }
    await dispatch(setUser(updated))
    await dispatch(updateUser(user.id, updated))
    setLoading(false)
    history.push('/profile')
  }

  return (
    <Content width={600}>
      <Form onSubmit={handleSave}>
        <Label>
          <LabelName>{t('profile.name')}</LabelName>
          <LabelInput
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={t('profile.nameDesc')}
          />
        </Label>
        <Label>
          <LabelName>{t('profile.city')}</LabelName>
          <PlacesAutocomplete
            value={cityText}
            onChange={value => setCityText(value)}
            onSelect={handleSelectCity}
            searchOptions={{ types: ['(cities)'] }}
          >
            {props => (
              <PlacesContainer>
                <LabelInput
                  {...props.getInputProps()}
                  placeholder={t('location.city')}
                />
                <PlacesSuggestions>
                  {props.suggestions.map(suggestion => (
                    <Suggestion {...props.getSuggestionItemProps(suggestion)}>
                      {suggestion.description}
                    </Suggestion>
                  ))}
                </PlacesSuggestions>
              </PlacesContainer>
            )}
          </PlacesAutocomplete>
        </Label>
        <Label>
          <LabelName>{t('profile.birthday')}</LabelName>
          <LabelDate
            selected={birthday ? new Date(birthday) : null}
            dateFormat={'dd/MM/yyyy'}
            onChange={date => setBirthday(date)}
            showYearDropdown
            dateFormatCalendar={'MMMM'}
            yearDropdownItemNumber={15}
            scrollableYearDropdown
          />
        </Label>
        <Label>
          <LabelName>{t('profile.sex')}</LabelName>
          <LabelSelect
            value={sex}
            onChange={e => setSex(e.target.value)}
            placeholder={t('profile.sexDesc')}
          >
            <option value={'male'}>{t('profile.male')}</option>
            <option value={'female'}>{t('profile.female')}</option>
          </LabelSelect>
        </Label>
        <Label>
          <LabelName>{t('profile.church')}</LabelName>
          <LabelInput
            value={church}
            onChange={e => setChurch(e.target.value)}
            placeholder={t('profile.churchDesc')}
          />
        </Label>
        <Label>
          <LabelName>{t('profile.bio')}</LabelName>
          <LabelTextarea
            value={bio}
            onChange={e => setBio(e.target.value)}
            placeholder={t('profile.bioDesc')}
          />
        </Label>
        <Label>
          <LabelName>{t('profile.testimony')}</LabelName>
          <LabelTextarea
            value={testimony}
            onChange={e => setTestimony(e.target.value)}
            placeholder={t('profile.testimonyDesc')}
          />
        </Label>
        <Label>
          <LabelName>{t('profile.website')}</LabelName>
          <LabelInput
            value={website}
            onChange={e => setWebsite(e.target.value)}
            placeholder={t('profile.websiteDesc')}
          />
        </Label>
        <Buttons>
          <ButtonsLeft>
            <ButtonActionDelete onClick={handleDelete}>
              {t('actions.deleteAccount')}
            </ButtonActionDelete>
          </ButtonsLeft>
          <ButtonsRight>
            <ButtonLink to={'/profile'}>{t('actions.cancel')}</ButtonLink>
            <ButtonAction type={'submit'}>{t('actions.save')}</ButtonAction>
          </ButtonsRight>
        </Buttons>
      </Form>

      {Boolean(loading) && <Spinner />}

      {Boolean(modal) && (
        <ModalActions options={options} onClose={() => setModal(false)} />
      )}
    </Content>
  )
}

const Form = styled.form`
  flex: 1;
  width: 600px;
`
const Label = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`
const LabelName = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.black3};
  margin-bottom: 5px;
`
const LabelInput = styled.input`
  width: 100%;
  padding: 5px 10px;
  font-family: OpenSans;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  box-sizing: border-box;
  color: ${colors.black2}
  background: ${colors.white2};
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray2}
  }
`
const LabelDate = styled(DatePicker)`
  width: 100%;
  padding: 5px 10px;
  font-family: OpenSans;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  box-sizing: border-box;
  color: ${colors.black2}
  background: ${colors.white2};
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray2}
  }
`
const LabelSelect = styled.select`
  width: 100%;
  padding: 5px 10px;
  font-family: OpenSans;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  box-sizing: border-box;
  color: ${colors.black2}
  background: ${colors.white2};
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray2}
  }
`
const LabelTextarea = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 10px;
  resize: none;
  font-family: OpenSans;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  box-sizing: border-box;
  color: ${colors.black2}
  background: ${colors.white2};
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray2}
  }
`
const PlacesContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const PlacesSuggestions = styled.div`
  display: flex;
  flex-direction: column;
`
const Suggestion = styled.button`
  width: 100%;
  text-align: left;
  font-family: OpenSans;
  font-size: 14px;
  border: 0;
  background: 0;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
`
const Buttons = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between;
`
const ButtonsLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`
const ButtonsRight = styled.div`
  display: flex;
  justify-content: flex-end;
`
const ButtonAction = styled.button`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  min-width: 150px;
  color: ${colors.white1};
  background: ${colors.praticantes1};
  border: none;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: none;
`
const ButtonActionDelete = styled.button`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  min-width: 150px;
  color: ${colors.gray5};
  border: 1px solid ${colors.gray5};
  background: none;
  cursor: pointer;
  text-decoration: none;
`
const ButtonLink = styled(Link)`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  min-width: 150px;
  color: ${colors.white1};
  background: ${colors.gray5};
  border: none;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: none;
`

export default memo(ProfileEdit)
