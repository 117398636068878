/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { getPost, getComments } from 'app/modules/posts'
import { useSelector, useDispatch } from 'react-redux'
import Content from 'app/components/Content'
import Post from 'app/components/Post'
import Loading from 'app/components/Loading'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import styled from 'styled-components'

const PostContainer = () => {
  const dispatch = useDispatch()
  const { feed, explore } = useSelector(state => state.posts)
  const [post, setPost] = useState({})
  const [loading, setLoading] = useState(true)
  const [comments, setComments] = useState([])
  const { id } = useParams()

  useEffect(() => {
    window.setTimeout(async () => {
      const data = await dispatch(getPost(id))
      setPost(data)
      const dataComments = await dispatch(
        getComments(id, { skip: 0, limit: 30 })
      )
      setComments(dataComments)
      setLoading(false)
    }, 1500)
  }, [feed, explore])

  if (loading) {
    return (
      <Content>
        <Loading />
      </Content>
    )
  }

  return (
    <Content content>
      <Helmet title={getTitle(post.description || 'post')} />
      <Wrapper>
        {Boolean(post) && Boolean(post.id) && (
          <Post
            post={post}
            source={'post'}
            comments={comments}
            expanded={true}
          />
        )}
      </Wrapper>
    </Content>
  )
}

const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  width: 600px;
`

export default PostContainer
