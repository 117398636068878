import React, { memo } from 'react'
import styled from 'styled-components'
import { Paperclip, Download } from 'react-feather'
import { getFileExtension } from 'app/helpers/text'
import colors from 'app/config/colors'
import { t } from 'app/locales'

const Attachments = ({ attachments = [], handleDownload }) => (
  <Wrapper>
    {attachments.map(attachment => (
      <Attachment
        key={attachment.id}
        onClick={() => handleDownload(attachment)}
      >
        <Extension>
          <Paperclip size={16} color={colors.white1} />
          <ExtensionName>{getFileExtension(attachment.name)}</ExtensionName>
        </Extension>
        <Description>
          <Name>{attachment.name}</Name>
          <Downloads>{t('feed.downloads', attachment.downloads)}</Downloads>
        </Description>
        <Button>
          <Download size={22} color={colors.gray2} />
        </Button>
      </Attachment>
    ))}
  </Wrapper>
)

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
`
const Attachment = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${colors.white2};
  border-radius: 4px;
  padding: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: none;
  border: none;
`
const Extension = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.red1};
  border-radius: 4px;
`
const ExtensionName = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 5px;
  color: ${colors.white1};
`
const Description = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 10px;
`
const Name = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.black2};
  text-align: left;
`
const Downloads = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 12px;
  padding-top: 3px;
  color: ${colors.black2};
`
const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

export default memo(Attachments)
