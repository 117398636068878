/* eslint-disable array-callback-return */

import React, { memo, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { formatDistanceStrict } from 'date-fns'
import { Heart, Trash2, Edit2 } from 'react-feather'
import { configLocale } from 'app/locales'
import colors from 'app/config/colors'
import UserPhoto from 'app/components/UserPhoto'
import { slugifyHashtag } from 'app/helpers/text'
import linkifyStr from 'linkifyjs/string'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import TextareaAutosize from 'react-textarea-autosize'
import { t } from 'app/locales'

const Comments = props => {
  const { comments = [] } = props
  const { user } = useSelector(state => state.app)
  const [text, setText] = useState('')
  const [mouseOverId, setMouseOverId] = useState(null)
  const [commentEditId, setCommentEditId] = useState(null)
  const [commentEditText, setCommentEditText] = useState(null)

  const handleNewComment = async e => {
    e.preventDefault()
    props.handleCommentCreate(text)
    setText('')
  }

  const handleCommentLike = async comment => {
    props.handleCommentLike(comment)
  }

  const handleCommentLikes = async comment => {
    props.handleCommentLikes(comment)
  }

  const handleCommentEdit = async comment => {
    setCommentEditId(comment.id)
    setCommentEditText(comment.comment)
  }

  const handleCommentUpdate = async () => {
    setCommentEditId(null)
    setCommentEditText(null)
    props.handleCommentUpdate(commentEditId, commentEditText)
  }

  const handleCommentDelete = async comment => {
    props.handleCommentDelete(comment.id)
  }

  const handleCommentText = comment => {
    let strings = comment.match(/\*[^*]+\*/g)
    if (strings && strings.length) {
      strings.map(text => {
        var replaced = `<b>${text.replace(/\*/g, '')}</b>`
        comment = comment.replace(text, replaced)
      })
    }
    let hashtags = comment.match(/#([0-9A-zÀ-ÖØ-öø-ÿ]+)/g)
    if (hashtags && hashtags.length) {
      hashtags.map(text => {
        var replaced = `<span>${text}</span>`
        replaced = `<a href="/hashtags/${slugifyHashtag(text)}">${replaced}</a>`
        comment = comment.replace(text, replaced)
      })
    }
    return parse(comment)
  }

  return (
    <Wrapper>
      {comments.map(comment => (
        <Comment
          key={comment.id}
          onMouseEnter={() => setMouseOverId(comment.id)}
          onMouseLeave={() => setMouseOverId(null)}
        >
          <UserPhoto user={comment.owner} />
          <Content>
            <ContentLine>
              <ContentLineName to={`/profile/${comment.owner.id}`}>
                {comment.owner.name}
              </ContentLineName>
            </ContentLine>
            <ContentLine>
              {comment.id === commentEditId && (
                <EditComment onSubmit={handleCommentUpdate}>
                  <EditCommentInput
                    type={'text'}
                    placeholder={t('feed.editComment')}
                    onChange={e => setCommentEditText(e.target.value)}
                    value={commentEditText}
                    autoFocus={true}
                  />
                  <EditCommentButton type={'submit'}>
                    {t('actions.update')}
                  </EditCommentButton>
                </EditComment>
              )}
              {comment.id !== commentEditId && (
                <ContentLineComment>
                  {handleCommentText(linkifyStr(comment.comment))}
                </ContentLineComment>
              )}
            </ContentLine>
            <ContentLine>
              <ContentLineTime>
                {formatDistanceStrict(
                  new Date(comment.created_at),
                  new Date(),
                  configLocale()
                )}{' '}
                {Boolean(comment.updated) &&
                  ` •  ${t('feed.updated').toLowerCase()}`}
              </ContentLineTime>
              {Boolean(comment.likes) && (
                <ContentLineLikes onClick={() => handleCommentLikes(comment)}>
                  {t('feed.likes', comment.likes)}
                </ContentLineLikes>
              )}
            </ContentLine>
          </Content>
          <Action>
            <ActionLike onClick={() => handleCommentLike(comment)}>
              {Boolean(comment.liked) && (
                <Heart
                  color={colors.red1}
                  size={16}
                  style={{ fill: colors.red1 }}
                />
              )}
              {!Boolean(comment.liked) && (
                <Heart color={colors.gray2} size={16} />
              )}
            </ActionLike>
          </Action>
          {user.id === comment.owner.id &&
            comment.id === mouseOverId &&
            !commentEditId && (
              <Actions>
                <ActionButton onClick={() => handleCommentEdit(comment)}>
                  <Edit2 size={16} color={colors.gray2} />
                </ActionButton>
                <ActionButton onClick={() => handleCommentDelete(comment)}>
                  <Trash2 size={16} color={colors.gray2} />
                </ActionButton>
              </Actions>
            )}
        </Comment>
      ))}
      <Comment border={true}>
        <UserPhoto user={user} />
        <NewComment onSubmit={handleNewComment}>
          <NewCommentInput
            type={'text'}
            placeholder={t('feed.addComment')}
            onChange={e => setText(e.target.value)}
            value={text}
          />
          <NewCommentButton type={'submit'}>
            {t('actions.publish')}
          </NewCommentButton>
        </NewComment>
      </Comment>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
  background: ${colors.white1};
  border-top: 1px solid ${colors.gray1};
`
const Comment = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${colors.white1};
  padding: 10px;
  text-decoration: none;
  border-top: ${props => (props.border ? 1 : 0)}px solid ${colors.gray1};
  box-sizing: border-box;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
`
const ContentLine = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
`
const ContentLineName = styled(Link)`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.black2};
  text-decoration: none;
`
const ContentLineTime = styled.div`
  font-family: OpenSans;
  font-weight: lighter;
  font-size: 12px;
  color: ${colors.gray2};
  text-decoration: none;
  margin-top: 5px;
`
const ContentLineLikes = styled.div`
  flex: 1;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${colors.gray2};
  text-decoration: none;
  margin-top: 5px;
  margin-left: 15px;
  cursor: pointer;
`
const ContentLineComment = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black2};
  margin-top: 5px;
  white-space: pre-wrap;
  overflow: hidden;
  a,
  span {
    color: ${colors.praticantes1};
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }
`
const EditComment = styled.form`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const EditCommentInput = styled(TextareaAutosize)`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: OpenSans;
  font-weight: regular;
  font-size: 14px;
  color: ${colors.black1};
  border: none;
  background: none;
  height: 18px;
  resize: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray2};
  }
`
const EditCommentButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${colors.praticantes1};
  background: none;
  border: none;
  cursor: pointer;
`
const Action = styled.div`
  align-self: center;
  padding-left: 15px;
`
const ActionLike = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`
const Actions = styled.div`
  position: absolute;
  right: 0;
  top: 20px;
  display: flex;
  background: white;
  align-self: center;
`
const ActionButton = styled.button`
  border: 0;
  background: none;
  padding: 0;
  padding: 5px;
  cursor: pointer;
`
const NewComment = styled.form`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
`
const NewCommentInput = styled(TextareaAutosize)`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: OpenSans;
  font-weight: regular;
  font-size: 14px;
  color: ${colors.black1};
  border: none;
  background: none;
  height: 18px;
  resize: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray2};
  }
`
const NewCommentButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${colors.praticantes1};
  background: none;
  border: none;
  cursor: pointer;
`

export default memo(Comments)
