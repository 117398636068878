import api, { withConfig } from 'app/api'
import axios from 'axios'

export default {
  createAction: data => {
    return withConfig(config => {
      return axios
        .post(api`/v1/actions`, data, config)
        .then(({ data }) => data.data)
    })
  },
  createAlias: data => {
    return withConfig(config => {
      return axios
        .put(api`/v1/actions/alias`, data, config)
        .then(({ data }) => data.data)
    })
  }
}
