/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import Content from 'app/components/Content'
import styled from 'styled-components'
import colors from 'app/config/colors'
import Spinner from 'app/components/Spinner'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import { t } from 'app/locales'

const AdvertisementContainer = () => {
  const { id } = useParams()
  const { advertisements } = useSelector(state => state.advertisements)
  const [advertisement, setAdvertisement] = useState(null)

  useEffect(() => {
    const data = advertisements.filter(ad => ad.id === id)
    setAdvertisement(data[0])
  }, [])

  if (advertisement == null) {
    return <Spinner />
  }

  return (
    <Content width={900}>
      <Helmet title={getTitle(advertisement.title)} />
      <Advertisement>
        <Image>
          <ImageThumbnail src={advertisement.image} />
        </Image>
        {Boolean(advertisement.title.length) && (
          <Title>{advertisement.title}</Title>
        )}
        {Boolean(advertisement.summary.length) && (
          <Summary>{advertisement.summary}</Summary>
        )}
        {Boolean(advertisement.content.length) && (
          <Description>
            {advertisement.content.replace(/\\n/g, '\n')}
          </Description>
        )}
        {Boolean(advertisement.link) && (
          <Button href={advertisement.link} target={'_blank'}>
            {t('actions.seeMore')}
          </Button>
        )}
      </Advertisement>
    </Content>
  )
}

const Advertisement = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  background: ${colors.white1};
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  text-decoration: none;
  overflow: hidden;
  margin: 8px;
`
const Image = styled.div`
  width: 100%;
  height: 450px;
  overflow: hidden;
`
const ImageThumbnail = styled.img`
  width: 100%;
`
const Title = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.black3};
  padding: 10px;
  padding-bottom: 0;
`
const Summary = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: ${colors.black3};
  padding: 10px;
  padding-bottom: 0;
  white-space: pre-line;
`
const Description = styled.div`
  font-family: OpenSans;
  font-weight: regular;
  font-size: 14px;
  color: ${colors.black3};
  padding: 10px;
  padding-bottom: 0;
  white-space: pre-line;
`
const Button = styled.a`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${colors.white1};
  text-align: center;
  text-transform: uppercase;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 4px;
  background: ${colors.praticantes1};
  text-decoration: none;
`

export default memo(AdvertisementContainer)
