/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import AsyncStorage from '@react-native-community/async-storage'
import { logout } from 'app/modules/app'
import { followUser, unfollowUser } from 'app/modules/users'
import { subscribeUser, unsubscribeUser } from 'app/modules/users'
import { getUser, getUserPosts, getUserBookmarks } from 'app/modules/users'
import { useSelector, useDispatch } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Bell, Bookmark, Menu } from 'react-feather'
import InfiniteScroll from 'react-infinite-scroller'
import Post from 'app/components/Post'
import Content from 'app/components/Content'
import UserPhoto from 'app/components/UserPhoto'
import Modal from 'app/components/Modal'
import Followers from 'app/components/Profile/Followers'
import Following from 'app/components/Profile/Following'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import Loading from 'app/components/Loading'
import colors from 'app/config/colors'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import { t } from 'app/locales'
import { linkify } from 'app/helpers/links'
import Logs from 'app/helpers/logs'

const Profile = () => {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.app)
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState(null)
  const [tabindex, setTabindex] = useState(0)
  const [posts, setPosts] = useState([])
  const [finishPosts, setFinishPosts] = useState(false)
  const [bookmarks, setBookmarks] = useState([])
  const [finishBookmarks, setFinishBookmarks] = useState(false)
  const [modalFollowers, setModalFollowers] = useState(false)
  const [modalFollowing, setModalFollowing] = useState(false)

  useEffect(() => {
    setLoading(true)
    setProfile(null)
    setPosts([])
    window.setTimeout(() => {
      loadUser(params && params.id ? params.id : user.id)
    }, 1000)
  }, [params])

  useEffect(() => {
    if (profile && profile.id) {
      loadUserPosts(1)
      loadUserBookmarks(1)
    }
  }, [profile])

  const loadUser = async id => {
    setLoading(true)
    const data = await dispatch(getUser(id))
    setProfile(data)
    setLoading(false)
  }

  const loadUserPosts = async page => {
    const limit = 10
    const skip = limit * (page - 1)
    Logs.track('User Fetch Posts #' + (skip / limit + 1))
    const data = await dispatch(getUserPosts(profile.id, { skip, limit }))
    setPosts([...posts, ...data])
    setFinishPosts(Boolean(data.length < limit))
  }

  const loadUserBookmarks = async page => {
    const limit = 10
    const skip = limit * (page - 1)
    const data = await dispatch(getUserBookmarks({ skip, limit }))
    setBookmarks([...bookmarks, ...data])
    setFinishBookmarks(Boolean(data.length < limit))
  }

  const handleFollow = async () => {
    if (profile && profile.follows) {
      setProfile({
        ...profile,
        follows: false,
        followers: profile.followers - 1
      })
      Logs.track('Action Unfollow')
      await dispatch(unfollowUser(profile.id))
    } else {
      setProfile({
        ...profile,
        follows: true,
        followers: profile.followers + 1
      })
      Logs.track('Action Follow')
      await dispatch(followUser(profile.id))
    }
  }

  const handleSubscribe = async () => {
    if (profile && profile.subscribed) {
      setProfile({ ...profile, subscribed: false })
      Logs.track('Action Unsubscribe')
      await dispatch(unsubscribeUser(profile.id))
    } else {
      setProfile({ ...profile, subscribed: true })
      Logs.track('Action Subscribe')
      await dispatch(subscribeUser(profile.id))
    }
  }

  const handleLogout = async () => {
    Logs.track('Action User Logout')

    window.OneSignal.removeExternalUserId()
    window.OneSignal.logoutEmail()

    history.push('/signup')
    await dispatch(logout())
    await AsyncStorage.clear()
  }

  const renderPosts = data => {
    return data.map(post => (
      <Post key={post.id} post={post} source={'profile'} comments={false} />
    ))
  }

  const renderEmpty = () => {
    return (
      <Empty>
        <EmptyText>{t('profile.empty')}</EmptyText>
      </Empty>
    )
  }

  return (
    <Content>
      {!Boolean(loading) && Boolean(profile) && Boolean(profile.id) && (
        <>
          <Helmet title={getTitle(profile.name)} />
          <UserContent>
            <UserRow>
              <UserPhoto user={profile} size={150} />

              <UserDesc>
                <UserInfos>
                  <UserName>{profile.name}</UserName>
                  {profile.city && profile.state && (
                    <UserLocation>
                      {profile.city + ', ' + profile.state}
                    </UserLocation>
                  )}
                </UserInfos>

                <UserCounters>
                  <UserCounter>
                    <UserCount>{profile.posts}</UserCount>
                    <UserCountDescription>
                      {t('profile.posts')}
                    </UserCountDescription>
                  </UserCounter>
                  <UserCounter onClick={() => setModalFollowers(true)}>
                    <UserCount>{profile.followers}</UserCount>
                    <UserCountDescription>
                      {t('profile.followers')}
                    </UserCountDescription>
                  </UserCounter>
                  <UserCounter onClick={() => setModalFollowing(true)}>
                    <UserCount>{profile.following}</UserCount>
                    <UserCountDescription>
                      {t('profile.following')}
                    </UserCountDescription>
                  </UserCounter>
                </UserCounters>

                {Boolean(profile.id === user.id) && (
                  <UserActions>
                    <UserActionsButtonHighlighted
                      flex={1}
                      active={false}
                      onClick={() => history.push('/profile/edit')}
                    >
                      {t('actions.edit')}
                    </UserActionsButtonHighlighted>
                    <UserActionsButton
                      flex={1}
                      active={true}
                      onClick={handleLogout}
                    >
                      {t('actions.logout')}
                    </UserActionsButton>
                  </UserActions>
                )}

                {Boolean(profile.id !== user.id) && (
                  <UserActions>
                    <UserActionsButton
                      flex={1}
                      active={profile.follows}
                      onClick={handleFollow}
                    >
                      {profile.follows
                        ? t('actions.unfollow')
                        : t('actions.follow')}
                    </UserActionsButton>
                    <UserActionsButton
                      flex={null}
                      active={profile.subscribed}
                      onClick={handleSubscribe}
                    >
                      {profile.subscribed ? (
                        <Bell size={14} color={colors.gray2} />
                      ) : (
                        <Bell size={14} color={colors.praticantes1} />
                      )}
                    </UserActionsButton>
                  </UserActions>
                )}
              </UserDesc>
            </UserRow>

            <UserLabels>
              {Boolean(profile.birthday) && (
                <UserLabel>
                  <UserLabelName>{t('profile.birthday')}</UserLabelName>
                  <UserlabelDescription>
                    {format(parseISO(profile.birthday), 'dd/MM')}
                  </UserlabelDescription>
                </UserLabel>
              )}
              {Boolean(profile.church) && (
                <UserLabel>
                  <UserLabelName>{t('profile.church')}</UserLabelName>
                  <UserlabelDescription>{profile.church}</UserlabelDescription>
                </UserLabel>
              )}
              {Boolean(profile.bio) && (
                <UserLabel>
                  <UserLabelName>{t('profile.bio')}</UserLabelName>
                  <UserlabelDescription>{profile.bio}</UserlabelDescription>
                </UserLabel>
              )}
              {Boolean(profile.testimony) && (
                <UserLabel>
                  <UserLabelName>{t('profile.testimony')}</UserLabelName>
                  <UserlabelDescription>
                    {profile.testimony}
                  </UserlabelDescription>
                </UserLabel>
              )}
              {Boolean(profile.website) && (
                <UserLabel>
                  <UserLabelName>{t('profile.website')}</UserLabelName>
                  <UserlabelLink
                    href={linkify(profile.website)}
                    target={'_blank'}
                  >
                    {profile.website}
                  </UserlabelLink>
                </UserLabel>
              )}
            </UserLabels>
          </UserContent>

          <PostsContent>
            <ContentTabs
              defaultIndex={0}
              onSelect={index => setTabindex(index)}
            >
              <ContentTabsMenu>
                <ContentTabsMenuTab active={tabindex === 0}>
                  <Menu
                    size={20}
                    color={tabindex === 0 ? colors.black3 : colors.gray2}
                  />
                  <ContentTabsMenuTabText active={tabindex === 0}>
                    {t('profile.posts')}
                  </ContentTabsMenuTabText>
                </ContentTabsMenuTab>

                {Boolean(profile.id === user.id) && (
                  <ContentTabsMenuTab active={tabindex === 1}>
                    <Bookmark
                      size={20}
                      color={tabindex === 1 ? colors.black3 : colors.gray2}
                    />
                    <ContentTabsMenuTabText active={tabindex === 1}>
                      {t('profile.bookmarks')}
                    </ContentTabsMenuTabText>
                  </ContentTabsMenuTab>
                )}
              </ContentTabsMenu>

              <TabPanel>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={1}
                  loadMore={loadUserPosts}
                  hasMore={!finishPosts}
                  element={Posts}
                  loader={<Loading key={null} />}
                >
                  {Boolean(posts.length) && renderPosts(posts)}
                  {Boolean(finishPosts) &&
                    !Boolean(posts.length) &&
                    renderEmpty()}
                </InfiniteScroll>
              </TabPanel>

              {Boolean(profile.id === user.id) && (
                <TabPanel>
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={1}
                    loadMore={loadUserBookmarks}
                    hasMore={!finishBookmarks}
                    element={Posts}
                    loader={<Loading key={null} />}
                  >
                    {Boolean(bookmarks.length) && renderPosts(bookmarks)}
                    {Boolean(finishBookmarks) &&
                      !Boolean(bookmarks.length) &&
                      renderEmpty()}
                  </InfiniteScroll>
                </TabPanel>
              )}
            </ContentTabs>
          </PostsContent>
        </>
      )}

      {Boolean(profile) && Boolean(modalFollowers) && (
        <Modal
          title={t('profile.followers')}
          onClose={() => setModalFollowers(false)}
        >
          <Followers user={profile} />
        </Modal>
      )}
      {Boolean(profile) && Boolean(modalFollowing) && (
        <Modal
          title={t('profile.following')}
          onClose={() => setModalFollowing(false)}
        >
          <Following user={profile} />
        </Modal>
      )}

      {Boolean(loading) && <Loading />}
    </Content>
  )
}

const UserContent = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
`
const UserRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
const UserDesc = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 50px;
`
const UserInfos = styled.div`
  display: flex;
  flex-direction: column;
`
const UserName = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 20px;
  color: ${colors.black3};
`
const UserLocation = styled.div`
  font-family: OpenSans;
  font-weight: regular;
  font-style: italic;
  font-size: 14px;
  color: ${colors.black3};
`
const UserCounters = styled.div`
  display: flex;
  width: calc(100% - 10px);
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-between;
  max-width: 350px;
`
const UserCounter = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: none;
  margin-right: 10px;
`
const UserCount = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.black3};
  text-align: center;
`
const UserCountDescription = styled.div`
  font-family: OpenSans;
  font-weight: regular;
  font-size: 14px;
  color: ${colors.black3};
`
const UserActions = styled.div`
  display: flex;
  width: calc(100% - 10px);
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-between;
  max-width: 350px;
`
const UserActionsButton = styled.button`
  flex: ${props => props.flex}
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${props => (props.active ? colors.gray2 : colors.praticantes1)};
  cursor: pointer;
  text-decoration: none;
  border: 1px solid ${props =>
    props.active ? colors.gray2 : colors.praticantes1};
  background: none;
  border-radius: 4px;
  height: 36px;
  padding: 0 15px;
  margin-right: 10px;
`

const UserActionsButtonHighlighted = styled(UserActionsButton)`
  color: ${colors.white1};
  border: 1px solid ${colors.praticantes3};
  background: ${colors.praticantes3};
`
const UserLabels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const UserLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`
const UserLabelName = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.black3};
`
const UserlabelDescription = styled.div`
  padding-top: 5px;
  font-family: OpenSans;
  font-weight: regular;
  font-size: 14px;
  color: ${colors.black3};
  white-space: pre-wrap;
`
const UserlabelLink = styled.a`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.praticantes1};
  text-decoration: none;
  text-transform: lowercase;
`
const PostsContent = styled.div`
  width: 600px;
  padding: 30px 0;
`
const ContentTabs = styled(Tabs)`
  width: 600px;
  flex-direction: row;
`
const ContentTabsMenu = styled(TabList)`
  width: 600px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
`
const ContentTabsMenuTab = styled(Tab)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  border-top: 1px solid ${colors.black3};
  border-color: ${props => (props.active ? colors.black3 : colors.gray2)};
  cursor: pointer;
`
const ContentTabsMenuTabText = styled.div`
  margin-left: 10px;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${props => (props.active ? colors.black3 : colors.gray2)};
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`
const Posts = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
`
const Empty = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
`
const EmptyText = styled.div`
  font-family: OpenSans;
  font-weight: regular;
  font-size: 14px;
  color: ${colors.gray2};
  text-align: center;
  cursor: pointer;
`

export default Profile
