import Immutable, { merge } from 'seamless-immutable'
import API from 'app/api/advertisements'

export const ADVERTISEMENTS_UPDATE = 'advertisements/ADVERTISEMENTS_UPDATE'

const initialState = Immutable({
  advertisements: []
})

export default (state = initialState, action) => {
  switch (action.type) {
    case ADVERTISEMENTS_UPDATE:
      return merge(state, {
        advertisements: action.advertisements
      })

    default:
      return state
  }
}

export const getAdvertisements = ({ skip = 0, limit = 10 }) => async (
  dispatch,
  getState
) => {
  return API.advertisements({ skip, limit })
    .then(list => {
      const unique = new Set()
      let baseList = []

      if (!Array.isArray(list)) {
        throw new Error('Not array')
      }

      if (skip > 0) {
        baseList = getState().advertisements.advertisements
      }

      const advertisements = baseList
        .concat(list)
        .filter(item => unique.size < unique.add(item.id).size)

      dispatch({ type: ADVERTISEMENTS_UPDATE, advertisements })

      return list
    })
    .catch(err => {
      console.log(err)
    })
}
