import API from 'app/api/actions'
import { isDevelopment } from 'app/helpers/app'

export default class Logs {
  static track = async event => {
    if (isDevelopment() === false) {
      await API.createAction({ source: 'web', event })
    }
  }

  static alias = async () => {
    if (isDevelopment() === false) {
      await API.createAlias()
    }
  }
}
