import React, { memo } from 'react'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { t } from 'app/locales'

const Totalizers = ({
  post,
  handleLikesButton,
  handleCommentsButton,
  handleBookmarksButton,
  handleViewsButton
}) => (
  <Wrapper>
    <Column>
      <Totalizer onClick={handleLikesButton}>
        {t('feed.likes', post.likes)}
      </Totalizer>
    </Column>
    <Column>
      <Totalizer onClick={handleCommentsButton}>
        {t('feed.comments', post.comments)}
      </Totalizer>
      <Separator>•</Separator>
      <Totalizer onClick={handleBookmarksButton}>
        {t('feed.bookmarks', post.bookmarks)}
      </Totalizer>
      <Separator>•</Separator>
      <Totalizer onClick={handleViewsButton}>
        {t('feed.views', post.views)}
      </Totalizer>
    </Column>
  </Wrapper>
)

const Wrapper = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  padding-bottom: 10px;
`
const Column = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Separator = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`
const Totalizer = styled.button`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 12px;
  color: ${colors.black2};
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`

export default memo(Totalizers)
