import { format } from 'date-fns'
import { formatDistanceStrict } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { enUS, ptBR } from 'date-fns/locale'
import { locale, timezone } from 'app/locales'

const locales = { 'en-US': enUS, 'pt-BR': ptBR }

const options = () => ({
  locale: locales[locale()],
  addSuffix: true,
  includeSeconds: false
})

export const newDate = () => {
  const zonedDate = utcToZonedTime(new Date(), timezone(), options())
  return zonedDate
}

export const parseUTC = date => {
  const parsed = zonedTimeToUtc(date, timezone(), options())
  return parsed
}

export const parseZoned = date => {
  const parsed = utcToZonedTime(date, timezone(), options())
  return parsed
}

export const formatDate = (date, dateFormat = 'P') => {
  return format(new Date(date), dateFormat, options())
}

export const formatDateDistance = date => {
  return formatDistanceStrict(new Date(date), new Date(), options())
}

export const formatLongDate = (date, dateFormat = 'PPPp') => {
  return format(new Date(date), dateFormat, options())
}
