import axios from 'axios'
import config from 'app/config'

export const parseLocation = location => {
  const data = {
    country: location.address_components.find(component =>
      component.types.includes('country')
    ),
    state: location.address_components.find(component =>
      component.types.includes('administrative_area_level_1')
    ),
    city: location.address_components.find(component =>
      component.types.includes('administrative_area_level_2')
    ),
    district: location.address_components.find(component =>
      component.types.includes('sublocality_level_1')
    )
  }

  const country = data.country ? data.country.long_name : null
  const state = data.state ? data.state.short_name : null
  const city = data.city ? data.city.long_name : null
  const district = data.district ? data.district.long_name : null
  const latitude = location.geometry ? location.geometry.location.lat : null
  const longitude = location.geometry ? location.geometry.location.lng : null

  return { country, state, city, district, latitude, longitude }
}

export const getLatLngByAddress = async address => {
  try {
    const api = 'https://maps.googleapis.com/maps/api/geocode/json'
    const response = await axios.get(
      `${api}?address=${address}&key=${config.googleApiKey}`
    )
    const location = response.data.results.find(result =>
      result.types.includes('postal_code')
    )

    if (!Boolean(location)) {
      return {}
    }

    return parseLocation(location)
  } catch (error) {
    return {}
  }
}

export const getAddressByLatLng = async (lat, lng) => {
  // todo
}
