import React, { memo } from 'react'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { Bookmark, Heart } from 'react-feather'
import { Share } from 'react-feather'
import { t } from 'app/locales'

const Actions = ({
  post,
  handleLikeButton,
  handleCommentButton,
  handleShareButton,
  handleBookmarkButton
}) => (
  <Wrapper>
    <Action onClick={handleLikeButton}>
      {Boolean(post.liked) && (
        <Heart size={20} color={colors.red1} style={{ fill: colors.red1 }} />
      )}
      {!Boolean(post.liked) && <Heart size={20} color={colors.black2} />}
      <ActionText>{t('actions.like')}</ActionText>
    </Action>
    {/*
    <Action onClick={handleCommentButton}>
      <FaRegComment size={20} color={colors.black2} />
      <ActionText>{t('actions.comment')}</ActionText>
    </Action>
    */}
    <Action onClick={handleShareButton}>
      <Share size={20} color={colors.black2} />
      <ActionText>{t('actions.share')}</ActionText>
    </Action>
    <Action onClick={handleBookmarkButton}>
      {Boolean(post.bookmarked) && (
        <Bookmark
          size={20}
          color={colors.black2}
          style={{ fill: 'currentcolor' }}
        />
      )}
      {!Boolean(post.bookmarked) && (
        <Bookmark size={20} color={colors.black2} />
      )}
      <ActionText>{t('actions.bookmark')}</ActionText>
    </Action>
  </Wrapper>
)

const Wrapper = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid ${colors.gray1};
`
const Action = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`
const ActionText = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 12px;
  padding-left: 10px;
  color: ${colors.black2};
`

export default memo(Actions)
