import Immutable, { merge } from 'seamless-immutable'
import API from 'app/api/notifications'

export const BADGE_UPDATE = 'notifications/BADGE_UPDATE'
export const NOTIFICATIONS_UPDATE = 'notifications/NOTIFICATIONS_UPDATE'

const initialState = Immutable({
  badge: 0,
  notifications: []
})

export default (state = initialState, action) => {
  switch (action.type) {
    case BADGE_UPDATE:
      return merge(state, {
        badge: action.badge
      })
    case NOTIFICATIONS_UPDATE:
      return merge(state, {
        notifications: action.notifications
      })

    default:
      return state
  }
}

export const getBadge = () => async (dispatch, getState) => {
  return API.badge()
    .then(badge => {
      dispatch({ type: BADGE_UPDATE, badge })
      return badge
    })
    .catch(err => {
      console.log(err)
    })
}

export const getNotifications = ({ skip = 0, limit = 10 }) => async (
  dispatch,
  getState
) => {
  return API.notifications({ skip, limit })
    .then(list => {
      const unique = new Set()
      let baseList = []

      if (!Array.isArray(list)) {
        throw new Error('Not array')
      }

      if (skip > 0) {
        baseList = getState().notifications.notifications
      }

      const notifications = baseList
        .concat(list)
        .filter(item => unique.size < unique.add(item.id).size)

      dispatch({ type: NOTIFICATIONS_UPDATE, notifications })

      return list
    })
    .catch(err => {
      console.log(err)
    })
}

export const seeNotifications = () => async (dispatch, getState) => {
  return API.see()
    .then(list => {
      const notifications = getState().notifications.notifications
      const updated = notifications.map(notification => {
        return { ...notification, seen: true }
      })

      dispatch({ type: BADGE_UPDATE, badge: 0 })
      dispatch({ type: NOTIFICATIONS_UPDATE, notifications: updated })

      return list
    })
    .catch(err => {
      console.log(err)
    })
}
