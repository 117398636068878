export default class colors {
  // Custom colors
  static praticantes1 = '#23568A'
  static praticantes2 = '#29B1C1'
  static praticantes3 = '#0189A4'
  static praticantes4 = '#ff467d'

  // Brands
  static facebook = '#4267b2'
  static google = '#ea4335'

  // General colors
  static black1 = '#000000'
  static black2 = '#262626'
  static black3 = '#4c4c4c'

  static white1 = '#ffffff'
  static white2 = '#fafafa'

  static gray1 = '#dbdbdb'
  static gray2 = '#b0b0b0'
  static gray3 = '#eaeaea'
  static gray4 = '#767676'
  static gray5 = '#9f9f9f'

  static red1 = '#e72f31'
  static red2 = '#f35e60'
  static red3 = '#ffd0d0'

  static blue1 = '#3897f0'
}
