export default {
  general: {
    scheduled: 'Scheduled to '
  },
  signin: {
    title: 'Enter your details',
    subtitle:
      'Fill in the fields below or use \n your Facebook/Gmail to create your account',
    email: 'Your email',
    emailRequired: 'The e-mail field is mandatory',
    password: 'Your password',
    passwordRequired: 'The password field is mandatory',
    button: 'Log in',
    signup: "Don't have an account? Create here",
    forgot: 'I forgot my password',
    fail: 'Could not authenticate your user'
  },
  signup: {
    title: 'Create your account',
    subtitle:
      'Fill in the fields below or use \n your Facebook/Gmail to create your account',
    name: 'Your name',
    nameRequired: 'The name field is required',
    email: 'Your email',
    emailRequired: 'The e-mail field is mandatory',
    password: 'Set a password',
    passwordRequired: 'The password field is mandatory',
    button: 'Create an account',
    signin: 'Already have an account? Access here',
    fail: 'We were unable to create your account',
    terms: {
      one: 'By clicking on one of the options above, you agree with the',
      two: '  terms of use ',
      three: 'Praticantes.'
    }
  },
  recovery: {
    title: 'Redefine password',
    subtitle: 'Oops! It happens!',
    description: "But don't worry, you can still reset your password here.",
    email: 'Email',
    emailRequired: 'The e-mail field is mandatory',
    send: 'Send email',
    message: 'You will receive a link to reset your password',
    fail: 'Your password could not be reset'
  },
  confession: {
    title: 'Declaration of Faith',
    description:
      'I believe in the eternal God, creator of all things, in Jesus Christ - son of God and in the Holy Spirit.\n\nI believe there is no other God.\n\nI believe the Bible is the unchanging Word of God.\n\nI believe that Jesus Christ is the way, the truth and the life; the only mediator through which we can be reconciled to God.\n\nI believe that Jesus is an eternal being, became a man, lived a blameless life here on earth, died for the remission of our sins, rose again, was exalted at the right hand of God, and will return in power and glory.\n \nI believe in Jesus Christ as Lord and Savior of my life.\n\nI believe in the habitation of the Holy Spirit in my life.',
    button: 'Amen, I agree!'
  },
  location: {
    title: 'Region',
    subtitle: "Let's start!",
    description:
      'Fill in your city and state to improve your app experience by region',
    city: 'Enter your city, state',
    save: 'To save',
    fail: 'Enter your city and state'
  },
  picture: {
    title: 'Image upload',
    subtitle: "Now let's define your profile picture",
    skip: 'Finish without photo'
  },
  terms: {
    title: 'Terms of use',
    back: 'Back to registration'
  },
  notifications: {
    title: 'Notifications'
  },
  feed: {
    title: 'Feed',
    empty: "You're not following anybody.",
    addComment: 'Add a comment',
    suggested: 'Suggested publication',
    updated: 'Edited',
    sponsored: 'Sponsored publication',
    scheduled: 'Publication scheduled for',
    likes: {
      plural: value => `${value} likes`,
      singular: value => `${value} like`
    },
    comments: {
      plural: value => `${value} comments`,
      singular: value => `${value} comment`
    },
    views: {
      plural: value => `${value} views`,
      singular: value => `${value} view`
    },
    bookmarks: {
      plural: value => `${value} bookmarks`,
      singular: value => `${value} bookmark`
    },
    downloads: {
      plural: value => `${value} downloads`,
      singular: value => `${value} download`
    }
  },
  publish: {
    title: 'Publish',
    description: 'Click here to write',
    photo: 'Photograph',
    video: 'Video',
    attachment: 'Document',
    time: 'Hour',
    scheduled: 'Schedule publication',
    scheduledSelectDate: 'Select a date',
    scheduleConfirm: 'Confirm schedule',
    scheduledTo: 'Scheduled to',
    scheduledDelete: 'Remove schedule',
    scheduledEmpty: 'This publication has no schedule',
    descriptionEmpty: 'Your publication is out of content',
    tagsEmpty: 'Select a tag for the publication',
    fail: 'Your post could not be saved',
    success: 'Publication saved successfully',
    create: 'Publish'
  },
  explore: {
    title: 'Explore',
    search: 'Search',
    posts: 'Publications',
    emptyPosts: 'There are no posts to display',
    users: 'Users',
    emptyUsers: 'There are no users to display',
    sortDate: 'Most recent',
    sortPopularity: 'More popular'
  },
  advertisements: {
    title: 'Advertisements'
  },
  post: {
    title: 'Publication',
    likes: 'Likes'
  },
  actions: {
    like: 'Like',
    comment: 'Comment',
    share: 'To share',
    bookmark: 'To save',
    publish: 'Publish',
    follow: 'follow',
    unfollow: 'Unfollow',
    subscribe: 'Receive notifications',
    unsubscribe: 'Cancel notifications',
    report: 'Report',
    delete: 'Delete',
    seeMore: 'see more',
    edit: 'To edit',
    logout: 'Get out',
    cancel: 'Cancel',
    save: 'To save',
    update: 'Update',
    deleteAccount: 'Delete my profile',
    deleteAccountYes: 'Yes, delete my profile',
    deleteAccountNo: 'Cancel'
  },
  profile: {
    title: 'Profile',
    posts: 'Publications',
    followers: 'Followers',
    following: 'Following',
    bookmarks: 'Saved publications',
    empty: 'There is nothing to show',
    name: 'Name',
    city: 'City',
    nameDesc: 'Full name',
    birthday: 'Birthday',
    birthdayDesc: 'The year will not be visible to other users',
    sex: 'Sex',
    sexDesc: 'Enter your gender',
    church: 'Church',
    churchDesc: 'The church you attend',
    bio: 'Bio',
    bioDesc: 'Tell us a little more about yourself',
    testimony: 'Personal testimony',
    testimonyDesc:
      'Tell us what God did in your life to encourage the brothers and glorify God!',
    website: 'site',
    websiteDesc: 'Promote your presence online',
    male: 'Male',
    female: 'Female'
  }
}
