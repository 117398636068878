import React, { memo } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { likePost, dislikePost } from 'app/modules/posts'
import User from 'app/components/PostExplore/User'
import Entries from 'app/components/Post/Entries'
import Description from 'app/components/Post/Description'
import Actions from 'app/components/PostExplore/Actions'
import colors from 'app/config/colors'
import { Link } from 'react-router-dom'
import Logs from 'app/helpers/logs'

const Post = ({ post }) => {
  const dispatch = useDispatch()

  const handleLikeButton = async e => {
    e.preventDefault()
    if (post.liked) {
      Logs.track('Action Dislike')
      await dispatch(dislikePost(post.id))
    } else {
      Logs.track('Action Like')
      await dispatch(likePost(post.id))
    }
    return false
  }

  return (
    <Wrapper to={`/posts/${post.id}`}>
      {Boolean(post.owner) && <User post={post} />}
      {Boolean(post.entries && post.entries.length) && (
        <Entries entries={post.entries} size={280} />
      )}
      {Boolean(post.description && post.description.length) && (
        <Description description={post.description} limit={200} />
      )}
      <Actions post={post} handleLikeButton={handleLikeButton} />
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  width: 31%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  background: ${colors.white1};
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  text-decoration: none;
  overflow: hidden;
  margin: 1%;
`

export default memo(Post)
