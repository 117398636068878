import React, { memo } from 'react'
import styled from 'styled-components'
import colors from 'app/config/colors'

const ShareButton = ({ Button, Icon, url, title }) => (
  <Wrapper>
    <Button url={url}>
      <ButtonContent>
        <Icon size={32} round />
        <ButtonText>{title}</ButtonText>
      </ButtonContent>
    </Button>
  </Wrapper>
)

const Wrapper = styled.div`
  flex: 1;
`
const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
`
const ButtonText = styled.div`
  font-family: OpenSans;
  font-weight: regular;
  font-size: 14px;
  color: ${colors.black1};
  margin-left: 20px;
`

export default memo(ShareButton)
