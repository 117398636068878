import Immutable, { merge } from 'seamless-immutable'

export const USER_UPDATE = 'app/USER_UPDATE'
export const LOCALE_UPDATE = 'app/LOCALE_UPDATE'

const initialState = Immutable({
  user: {},
  locale: 'pt-BR',
  timezone: 'America/Sao_Paulo'
})

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_UPDATE:
      return merge(state, {
        user: action.user
      })

    case LOCALE_UPDATE:
      return merge(state, {
        locale: action.locale
      })

    default:
      return state
  }
}

export const setUser = user => dispatch => {
  dispatch({ type: USER_UPDATE, user })
}

export const setLocale = locale => dispatch => {
  dispatch({ type: LOCALE_UPDATE, locale })
}

export const logout = () => dispatch => {
  dispatch({ type: USER_UPDATE, user: null })
}
