/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useState, useEffect } from 'react'
import { getAdvertisements } from 'app/modules/advertisements'
import { useSelector, useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import Content from 'app/components/Content'
import CardAdvertisement from 'app/components/CardAdvertisement'
import Loading from 'app/components/Loading'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'

const Advertisements = () => {
  const dispatch = useDispatch()
  const { advertisements } = useSelector(state => state.advertisements)
  const [finish, setFinish] = useState(false)

  useEffect(() => {
    window.setTimeout(() => {
      load(1)
    }, 1000)
  }, [])

  const load = async page => {
    const limit = 9
    const skip = limit * (page - 1)
    const advertisements = await dispatch(getAdvertisements({ skip, limit }))
    if (advertisements && advertisements.length < limit) {
      setFinish(true)
    }
  }

  return (
    <Content width={900}>
      <Helmet title={getTitle('advertisements')} />
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={load}
        hasMore={!finish}
        element={Wrapper}
        loader={<Loading key={null} />}
      >
        {Boolean(advertisements.length) &&
          advertisements.map(advertisement => (
            <CardAdvertisement
              key={advertisement.id}
              advertisement={advertisement}
            />
          ))}
      </InfiniteScroll>
    </Content>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`

export default memo(Advertisements)
