import React, { memo } from 'react'
import styled from 'styled-components'
import colors from 'app/config/colors'
import Highlighter from 'react-highlight-words'
import { extractHighlights } from 'app/helpers/text'
import Entries from 'app/components/Post/Entries'
import { Link } from 'react-router-dom'

const CardPost = ({ post, highlights = [] }) => (
  <Wrapper to={`/posts/${post.id}`}>
    <ContainerContent>
      <ContentUser>{post.owner.name}</ContentUser>
      <ContentDescription>
        <Highlighter
          highlightStyle={{ background: colors.praticantes3 }}
          searchWords={highlights}
          autoEscape={true}
          textToHighlight={extractHighlights(post.description, highlights, 2)}
        />
      </ContentDescription>
    </ContainerContent>
    {Boolean(post.entries.length) && (
      <ContainerPhoto>
        <Entries size={80} entries={post.entries} />
      </ContainerPhoto>
    )}
  </Wrapper>
)

const Wrapper = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: row;
  background: ${colors.white1};
  text-decoration: none;
  overflow: hidden;
  padding: 10px;
  border-bottom: 1px solid ${colors.gray1};
`
const ContainerPhoto = styled.div`
  width: 80px;
  margin-left: 10px;
`
const ContainerContent = styled.div`
  flex: 1;
`
const ContentUser = styled.span`
  font-family: OpenSans;
  font-size: 14px;
  color: ${colors.black1};
  font-weight: bold;
`
const ContentDescription = styled.div`
  font-family: OpenSans;
  font-size: 14px;
  color: ${colors.black1};
  font-weight: regular;
`

export default memo(CardPost)
