import Immutable, { merge } from 'seamless-immutable'
import API from 'app/api/posts'
import firebase from 'firebase/app'
import { v1 as uuid } from 'uuid'
import { upload } from 'app/helpers/wasabi'

export const FEED_UPDATE = 'posts/FEED_UPDATE'
export const EXPLORE_UPDATE = 'posts/EXPLORE_UPDATE'

const initialState = Immutable({
  feed: [],
  explore: []
})

export default (state = initialState, action) => {
  switch (action.type) {
    case FEED_UPDATE:
      return merge(state, {
        feed: action.feed
      })
    case EXPLORE_UPDATE:
      return merge(state, {
        explore: action.explore
      })

    default:
      return state
  }
}

export const getFeed = ({ skip = 0, limit = 10 }) => async (
  dispatch,
  getState
) => {
  return API.feed({ skip, limit })
    .then(list => {
      const unique = new Set()
      let baseList = []

      if (!Array.isArray(list)) {
        throw new Error('Not array')
      }

      if (skip > 0) {
        baseList = getState().posts.feed
      }

      const feed = baseList
        .concat(list)
        .filter(item => unique.size < unique.add(item.id).size)

      dispatch({ type: FEED_UPDATE, feed })

      return list
    })
    .catch(err => {
      console.log(err)
    })
}

export const getExplore = ({
  skip = 0,
  limit = 10,
  tag = null,
  sort = 'date'
}) => async (dispatch, getState) => {
  return API.explore({ skip, limit, tag, sort })
    .then(list => {
      const unique = new Set()
      let baseList = []

      if (!Array.isArray(list)) {
        throw new Error('Not array')
      }

      if (skip > 0) {
        baseList = getState().posts.explore
      }

      const explore = baseList
        .concat(list)
        .filter(item => unique.size < unique.add(item.id).size)

      dispatch({ type: EXPLORE_UPDATE, explore })

      return list
    })
    .catch(err => {
      console.log(err)
    })
}

export const getSearch = data => async () => {
  return API.search(data)
    .then(posts => {
      return posts
    })
    .catch(err => {
      console.log(err)
    })
}

export const getPostsByHashtag = ({
  skip = 0,
  limit = 10,
  hashtag
}) => async () => {
  return API.explore({ skip, limit, hashtag })
    .then(list => {
      return list
    })
    .catch(err => {
      console.log(err)
    })
}

export const getPost = id => async (dispatch, getState) => {
  return API.post(id)
    .then(post => {
      return post
    })
    .catch(err => {
      console.log(err)
    })
}

export const deletePost = id => async (dispatch, getState) => {
  return API.deletePost(id)
    .then(post => {
      return true
    })
    .catch(err => {
      console.log(err)
    })
}

export const getLikes = (id, { skip = 0, limit = 10 }) => async (
  dispatch,
  getState
) => {
  return API.likes(id, { skip, limit })
    .then(likes => {
      return likes
    })
    .catch(err => {
      console.log(err)
    })
}

export const likePost = id => async (dispatch, getState) => {
  const feed = getState().posts.feed.map(post => {
    if (post.id === id) {
      return { ...post, liked: true, likes: post.likes + 1 }
    }
    return post
  })
  dispatch({ type: FEED_UPDATE, feed })

  const explore = getState().posts.explore.map(post => {
    if (post.id === id) {
      return { ...post, liked: true, likes: post.likes + 1 }
    }
    return post
  })
  dispatch({ type: EXPLORE_UPDATE, explore })

  return API.like(id)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const dislikePost = id => async (dispatch, getState) => {
  const feed = getState().posts.feed.map(post => {
    if (post.id === id) {
      return { ...post, liked: false, likes: post.likes - 1 }
    }
    return post
  })
  dispatch({ type: FEED_UPDATE, feed })

  const explore = getState().posts.explore.map(post => {
    if (post.id === id) {
      return { ...post, liked: false, likes: post.likes - 1 }
    }
    return post
  })
  dispatch({ type: EXPLORE_UPDATE, explore })

  return API.dislike(id)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const getComments = (id, { skip = 0, limit = 10 }) => async () => {
  return API.comments(id, { skip, limit })
    .then(comments => {
      return comments
    })
    .catch(err => {
      console.log(err)
    })
}

export const likeComment = (postId, commentId) => async () => {
  return API.likeComment(postId, commentId)
    .then(() => {
      return true
    })
    .catch(err => {
      console.log(err)
    })
}

export const dislikeComment = (postId, commentId) => async () => {
  return API.dislikeComment(postId, commentId)
    .then(() => {
      return true
    })
    .catch(err => {
      console.log(err)
    })
}

export const getCommentLikes = (
  postId,
  commentId,
  { skip = 0, limit = 10 }
) => async () => {
  return API.getCommentLikes(postId, commentId, { skip, limit })
    .then(likes => {
      return likes
    })
    .catch(err => {
      console.log(err)
    })
}

export const bookmarkPost = id => async (dispatch, getState) => {
  const feed = getState().posts.feed.map(post => {
    if (post.id === id) {
      return { ...post, bookmarked: true, bookmarks: post.bookmarks + 1 }
    }
    return post
  })
  dispatch({ type: FEED_UPDATE, feed })

  const explore = getState().posts.explore.map(post => {
    if (post.id === id) {
      return { ...post, bookmarked: true, bookmarks: post.bookmarks + 1 }
    }
    return post
  })
  dispatch({ type: EXPLORE_UPDATE, explore })

  return API.bookmark(id)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const unbookmarkPost = id => async (dispatch, getState) => {
  const feed = getState().posts.feed.map(post => {
    if (post.id === id) {
      return { ...post, bookmarked: false, bookmarks: post.bookmarks - 1 }
    }
    return post
  })
  dispatch({ type: FEED_UPDATE, feed })

  const explore = getState().posts.explore.map(post => {
    if (post.id === id) {
      return { ...post, bookmarked: false, bookmarks: post.bookmarks - 1 }
    }
    return post
  })
  dispatch({ type: EXPLORE_UPDATE, explore })

  return API.unbookmark(id)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const downloadAttachment = (id, attachment) => async () => {
  return API.download({ id, attachment })
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const createComment = (postId, comment) => async (
  dispatch,
  getState
) => {
  const feed = getState().posts.feed.map(post => {
    if (post.id === postId) {
      return { ...post, comments: post.comments + 1 }
    }
    return post
  })
  dispatch({ type: FEED_UPDATE, feed })

  const explore = getState().posts.explore.map(post => {
    if (post.id === postId) {
      return { ...post, comments: post.comments + 1 }
    }
    return post
  })
  dispatch({ type: EXPLORE_UPDATE, explore })

  return API.createComment(postId, { comment })
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const updateComment = (id, comment) => async () => {
  return API.updateComment(id, { comment })
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const deleteComment = (postId, commentId) => async (
  dispatch,
  getState
) => {
  const feed = getState().posts.feed.map(post => {
    if (post.id === postId) {
      return { ...post, comments: post.comments - 1 }
    }
    return post
  })
  dispatch({ type: FEED_UPDATE, feed })

  const explore = getState().posts.explore.map(post => {
    if (post.id === postId) {
      return { ...post, comments: post.comments - 1 }
    }
    return post
  })
  dispatch({ type: EXPLORE_UPDATE, explore })

  return API.deleteComment(postId, commentId)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const createPost = ({
  description,
  entries,
  attachments,
  tags,
  scheduledAt
}) => async () => {
  const post = {
    description,
    tags,
    entries: [],
    attachments: [],
    scheduled_at: scheduledAt
  }

  if (entries && entries.length) {
    await Promise.all(
      entries.map(async entry => {
        const hash = uuid()
        const [type, format] = entry.type.split('/')

        if (type === 'image') {
          const { width, height, size } = entry
          const storage = firebase
            .storage()
            .ref('post_pictures')
            .child(hash)
          await storage.put(entry, { width, height })
          const url = await storage.getDownloadURL()
          post.entries.push({ width, height, size, url, type: 'picture' })
        }

        if (type === 'video') {
          if (format !== 'upload') {
            post.entries.push({
              type: 'video',
              provider: format,
              url: entry.url
            })
          }
        }
      })
    )
  }

  if (attachments && attachments.length) {
    const files = await Promise.all(attachments.map(upload))
    post.attachments = attachments.map((attachment, index) => ({
      name: attachment.name,
      size: attachment.size,
      mime: attachment.type,
      url: files[index]
    }))
  }

  return API.create(post)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const updatePost = (
  id,
  { description, tags, scheduledAt }
) => async () => {
  return API.update(id, { description, tags, scheduled_at: scheduledAt })
    .then(data => {
      return data
    })
    .catch(err => {
      console.log('err')
    })
}
