import React from 'react'
import ReactDOM from 'react-dom'
import firebase from 'firebase/app'
import 'firebase/auth'
import { Switch } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'app/lib/RouterProvider'
import { PersistGate } from 'redux-persist/lib/integration/react'
import AuthProvider from 'app/lib/AuthProvider'
import { toast } from 'react-toastify'
import ApplicationRoutes from 'app/routes'
import { store, persistor } from 'app/store'
import ptBR from 'date-fns/locale/pt-BR'
import enUS from 'date-fns/locale/en-US'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import Logs from 'app/helpers/logs'
import { isDevelopment } from 'app/helpers/app'
import config from 'app/config'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'app/assets/css/styles.css'

registerLocale('pt-BR', ptBR)
registerLocale('en-US', enUS)
setDefaultLocale('pt-BR')

toast.configure({
  autoClose: 4000,
  draggable: false,
  hideProgressBar: true,
  closeButton: false,
  className: 'toast',
  toastClassName: 'toast-content',
  position: toast.POSITION.BOTTOM_CENTER
})

const firebaseConfig = {
  apiKey: 'AIzaSyC3wyhASix166o_qZ7nDPh7JG472HAwXmI',
  authDomain: 'praticantes-27eeb.firebaseapp.com',
  databaseURL: 'https://praticantes-27eeb.firebaseio.com',
  projectId: 'praticantes-27eeb',
  storageBucket: 'praticantes-27eeb.appspot.com',
  messagingSenderId: '802817075816',
  appId: '1:802817075816:web:9bdf36d1e3d29755',
  measurementId: 'G-ZJYNRZ6J1D'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

window.setTimeout(() => {
  if (isDevelopment() === false) {
    window.OneSignal.push(() => {
      window.OneSignal.init({ appId: config.onesignalAppId })
      window.OneSignal.showNativePrompt()
      window.OneSignal.setDefaultTitle('Praticantes')

      // Log notification permission change
      window.OneSignal.on('notificationPermissionChange', permission => {
        if (permission.to === 'granted') {
          Logs.track('User Notifications Permission Allowed')
        } else if (permission.to === 'denied') {
          Logs.track('User Notifications Permission Denied')
        } else if (permission.to === 'default') {
          Logs.track('User Notifications Permission Ignored')
        }
      })
    })
  }
}, 4000)

const Root = ({ store }) => (
  <ReduxProvider store={store}>
    <PersistGate loading={<div>Carregando...</div>} persistor={persistor}>
      <RouterProvider>
        <Switch>
          <AuthProvider path={'/'} component={ApplicationRoutes} />
        </Switch>
      </RouterProvider>
    </PersistGate>
  </ReduxProvider>
)

ReactDOM.render(<Root store={store} />, document.getElementById('root'))
