/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useState, useEffect } from 'react'
import { getFeed } from 'app/modules/posts'
import { useSelector, useDispatch } from 'react-redux'
import Content from 'app/components/Content'
import Post from 'app/components/Post'
import InfiniteScroll from 'react-infinite-scroller'
import Loading from 'app/components/Loading'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import Logs from 'app/helpers/logs'

const Feed = () => {
  const dispatch = useDispatch()
  const { feed } = useSelector(state => state.posts)
  const [finish, setFinish] = useState(false)

  useEffect(() => {
    window.setTimeout(() => {
      load(1)
    }, 1500)
  }, [])

  const load = async page => {
    const limit = 10
    const skip = limit * (page - 1)
    Logs.track('User Fetch Feed #' + (skip / limit + 1))
    const posts = await dispatch(getFeed({ skip, limit }))
    if (posts && posts.length < limit) {
      setFinish(true)
    }
  }

  return (
    <Content>
      <Helmet title={getTitle()} />
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={load}
        hasMore={!finish}
        element={Posts}
        loader={<Loading key={null} />}
      >
        {Boolean(feed.length) &&
          feed.map(post => (
            <Post key={post.id} post={post} source={'feed'} comments={false} />
          ))}
      </InfiniteScroll>
    </Content>
  )
}

const Posts = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
`

export default memo(Feed)
