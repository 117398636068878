import React, { memo } from 'react'
import styled from 'styled-components'
import colors from 'app/config/colors'

const ModalActions = ({ options, onClose }) => (
  <Wrapper>
    <Modal>
      <Title>Selecione uma ação</Title>
      {options.map((option, i) => (
        <Action onClick={option.action} key={i}>
          {option.title}
        </Action>
      ))}
    </Modal>
    <Opacity onClick={onClose} />
  </Wrapper>
)

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`
const Opacity = styled.a`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.black1};
  border: none;
  opacity: 0.65;
  z-index: 200;
`
const Modal = styled.div`
  dislay: flex;
  flex-direction: column;
  width: 400px;
  background: ${colors.white1};
  border-radius: 12px;
  overflow: hidden;
  z-index: 300;
`
const Title = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${colors.black1};
  text-align: center;
  padding: 10px;
  background: ${colors.white2};
`
const Action = styled.button`
  flex: 1;
  width: 100%;
  font-family: OpenSans;
  font-weight: regular;
  font-size: 14px;
  color: ${colors.black1};
  background: none;
  border: none;
  padding: 12px;
  text-align: center;
  border-top: 1px solid ${colors.gray1};
  cursor: pointer;
  line-height: 1.5;
  margin: 0;
  min-height: 48px;
  padding: 4px 8px;
`

export default memo(ModalActions)
