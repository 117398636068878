import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export default {
  advertisements: data => {
    return withConfig(config => {
      return axios
        .get(api`/v1/advertisements${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    })
  }
}
