export default {
  general: {
    scheduled: 'Agendado para  '
  },
  signin: {
    title: 'Entre com seus dados',
    subtitle:
      'Preencha os campos abaixo ou use \n seu Facebook/Gmail para criar sua conta',
    email: 'Seu e-mail',
    emailRequired: 'O campo e-mail é obrigatório',
    password: 'Sua senha',
    passwordRequired: 'O campo senha é obrigatório',
    button: 'Entrar',
    signup: 'Não possui conta? Crie aqui',
    forgot: 'Esqueci minha senha',
    fail: 'Não foi possível autenticar seu usuário'
  },
  signup: {
    title: 'Crie sua conta',
    subtitle:
      'Preencha os campos abaixo ou use \n seu Facebook/Gmail para criar sua conta',
    name: 'Seu nome',
    nameRequired: 'O campo nome é obrigatório',
    email: 'Seu e-mail',
    emailRequired: 'O campo e-mail é obrigatório',
    password: 'Defina uma senha',
    passwordRequired: 'O campo senha é obrigatório',
    button: 'Criar conta',
    signin: 'Já possui conta? Acesse aqui',
    fail: 'Não foi possível criar sua conta',
    terms: {
      one: 'Ao clicar em uma das opções acima, você está de acordo com os',
      two: ' termos de uso ',
      three: 'do Praticantes.'
    }
  },
  recovery: {
    title: 'Redefinir senha',
    subtitle: 'Ops! Acontece!',
    description:
      'Mas não se preocupe, você ainda pode redefinir sua senha por aqui.',
    email: 'E-mail',
    emailRequired: 'O campo e-mail é obrigatório',
    send: 'Enviar e-mail',
    message: 'Você receberá um link para redefinir sua senha',
    fail: 'Não foi possível redefinir sua senha'
  },
  confession: {
    title: 'Declaração de Fé',
    description:
      'Creio no Deus eterno, criador de todas as coisas, em Jesus Cristo - filho de Deus e no Espírito Santo. \n\nCreio que não há outro Deus. \n\nCreio que a Bíblia é a imutável Palavra de Deus. \n\nCreio que Jesus Cristo é o caminho, a verdade e a vida; o único mediador através do qual podemos nos reconciliar com Deus. \n\nCreio que Jesus é um ser eterno, se tornou homem, viveu aqui na terra uma vida irrepreensível, morreu para remissão dos nossos pecados, ressuscitou, foi exaltado à destra de Deus, e voltará em poder e glória. \n\nCreio em Jesus Cristo como Senhor e Salvador da minha vida. \n\nCreio na habitação do Espírito Santo em minha vida.',
    button: 'Amém, eu concordo!'
  },
  location: {
    title: 'Região',
    subtitle: 'Vamos começar!',
    description:
      'Preencha sua cidade e estado para melhorarmos sua experência no app por região',
    city: 'Digite sua cidade, UF',
    save: 'Salvar',
    fail: 'Informe a sua cidade e estado'
  },
  picture: {
    title: 'Upload de imagem',
    subtitle: 'Agora vamos definir sua imagem de perfil',
    skip: 'Finalizar sem foto'
  },
  terms: {
    title: 'Termos de uso',
    back: 'Voltar para o cadastro'
  },
  notifications: {
    title: 'Notificações'
  },
  feed: {
    title: 'Feed',
    empty: 'Você não está seguindo ninguém',
    addComment: 'Adicione um comentário',
    suggested: 'Publicação sugerida',
    updated: 'Editado',
    sponsored: 'Publicação patrocinada',
    scheduled: 'Publicação agendada para',
    likes: {
      plural: value => `${value} curtidas`,
      singular: value => `${value} curtida`
    },
    comments: {
      plural: value => `${value} comentários`,
      singular: value => `${value} comentário`
    },
    views: {
      plural: value => `${value} visualizações`,
      singular: value => `${value} visualização`
    },
    bookmarks: {
      plural: value => `${value} favoritos`,
      singular: value => `${value} favorito`
    },
    downloads: {
      plural: value => `${value} baixaram`,
      singular: value => `${value} baixou`
    }
  },
  publish: {
    title: 'Publicar',
    description: 'Clique aqui pra escrever',
    photo: 'Foto',
    video: 'Video',
    attachment: 'Documento',
    time: 'Hora',
    scheduled: 'Agendar publicação',
    scheduledSelectDate: 'Selecione uma data',
    scheduleConfirm: 'Confirmar agendamento',
    scheduledTo: 'Agendado para',
    scheduledDelete: 'Remover agendamento',
    scheduledEmpty: 'Esta publicação não possui agendamento',
    descriptionEmpty: 'Sua publicação está sem conteúdo',
    tagsEmpty: 'Selecione uma tag para a publicação',
    fail: 'Não foi possível salvar sua publicação',
    success: 'Publicação salva com sucesso',
    create: 'Publicar'
  },
  explore: {
    title: 'Explorar',
    search: 'Buscar',
    posts: 'Publicações',
    emptyPosts: 'Não há publicações para mostrar',
    users: 'Usuários',
    emptyUsers: 'Não há usuários para mostrar',
    sortDate: 'Mais recentes',
    sortPopularity: 'Mais populares'
  },
  advertisements: {
    title: 'Parceiros'
  },
  post: {
    title: 'Publicação',
    likes: 'Curtidas'
  },
  actions: {
    like: 'Curtir',
    comment: 'Comentar',
    share: 'Compartilhar',
    bookmark: 'Salvar',
    publish: 'Publicar',
    follow: 'Seguir',
    unfollow: 'Deixar de seguir',
    subscribe: 'Receber notificações',
    unsubscribe: 'Cancelar notificações',
    report: 'Denunciar',
    delete: 'Excluir',
    seeMore: 'Veja mais',
    edit: 'Editar',
    logout: 'Sair',
    cancel: 'Cancelar',
    save: 'Salvar',
    update: 'Atualizar',
    deleteAccount: 'Deletar meu perfil',
    deleteAccountYes: 'Sim, deletar meu perfil',
    deleteAccountNo: 'Cancelar'
  },
  profile: {
    title: 'Perfil',
    posts: 'Publicações',
    followers: 'Seguidores',
    following: 'Seguindo',
    bookmarks: 'Publicações salvas',
    empty: 'Não há nada para mostrar',
    name: 'Nome',
    city: 'Cidade',
    nameDesc: 'Nome completo',
    birthday: 'Aniversário',
    birthdayDesc: 'O ano não ficará visível para outros usuários',
    sex: 'Sexo',
    sexDesc: 'Informe seu sexo',
    church: 'Igreja',
    churchDesc: 'A igreja que você frequenta',
    bio: 'Bio',
    bioDesc: 'Conte um pouco mais sobre você',
    testimony: 'Testemunho pessoal',
    testimonyDesc:
      'Conte o que Deus fez na sua vida para animar os irmãos e glorificar a Deus!',
    website: 'Site',
    websiteDesc: 'Divulgue sua presença online',
    male: 'Masculino',
    female: 'Feminino'
  }
}
