import React, { memo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { followUser, unfollowUser } from 'app/modules/users'
import { getFeed, getExplore, deletePost } from 'app/modules/posts'
import { formatDistanceStrict } from 'date-fns'
import { configLocale } from 'app/locales'
import { MoreHorizontal } from 'react-feather'
import UserPhoto from 'app/components/UserPhoto'
import ModalActions from 'app/components/ModalActions'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { t } from 'app/locales'

const User = ({ post }) => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.app)
  const [modal, setModal] = useState(false)
  const [options, setOptions] = useState([])

  const handleUserMoreButton = e => {
    //e.preventDefault()
    const opts = []
    if (user.id !== post.owner.id) {
      opts.push({
        title: t('actions.report'),
        action: async () => {
          window.open(
            `mailto:suporte@praticantes.org?subject=Denúncia do post ${post.id}&body=Gostaria de realizar essa denúncia no aplicativo Praticantes. -- Por favor, informe o motivo aqui --`,
            '_blank'
          )
          setModal(false)
        }
      })

      if (post.owner && post.owner.follows) {
        opts.push({
          title: t('actions.unfollow'),
          action: async () => {
            setModal(false)
            await dispatch(unfollowUser(post.owner.id))
            await dispatch(getFeed({}))
            await dispatch(getExplore({}))
          }
        })
      } else {
        opts.push({
          title: t('actions.follow'),
          action: async () => {
            setModal(false)
            await dispatch(followUser(post.owner.id))
            await dispatch(getFeed({}))
            await dispatch(getExplore({}))
          }
        })
      }
    } else {
      opts.push({
        title: t('actions.delete'),
        action: async () => {
          await dispatch(deletePost(post.id))
          await dispatch(getFeed({}))
          await dispatch(getExplore({}))
          setModal(false)
        }
      })
    }
    setOptions(opts)
    setModal(true)
  }

  return (
    <Wrapper>
      <Link to={`/profile/${post.owner.id}`}>
        <UserPhoto user={post.owner} />
      </Link>
      <UserLabel to={`/profile/${post.owner.id}`}>
        <UserLabelName>{post.owner.name}</UserLabelName>
        <UserLabelDate>
          {formatDistanceStrict(
            new Date(post.created_at),
            new Date(),
            configLocale()
          )}
        </UserLabelDate>
      </UserLabel>
      <UserActions onClick={handleUserMoreButton}>
        <MoreHorizontal size={22} color={colors.black1} />
      </UserActions>
      {Boolean(modal) && (
        <ModalActions options={options} onClose={() => setModal(false)} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  padding: 10px;
  border-bottom: 1px solid ${colors.gray1};
  text-decoration: none;
`
const UserLabel = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  text-decoration: none;
`
const UserLabelName = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 16px;
  color: ${colors.black1};
`
const UserLabelDate = styled.div`
  font-family: OpenSans;
  font-weight: lighter;
  font-size: 12px;
  color: ${colors.black1};
`
const UserActions = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`

export default memo(User)
