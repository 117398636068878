import { t } from 'app/locales'
import { toExcerpt } from 'app/helpers/text'

export const getTitle = title => {
  const name = 'Praticantes'
  switch (title) {
    case 'signup':
      return t('signup.title') + ' • ' + name
    case 'signin':
      return t('signin.title') + ' • ' + name
    case 'confession':
      return t('confession.title') + ' • ' + name
    case 'location':
      return t('location.title') + ' • ' + name
    case 'recovery':
      return t('recovery.title') + ' • ' + name
    case 'terms':
      return t('terms.title') + ' • ' + name
    case 'feed':
      return name
    case 'explore':
      return t('explore.title') + ' • ' + name
    case 'notifications':
      return t('notifications.title') + ' • ' + name
    case 'publish':
      return t('publish.title') + ' • ' + name
    case 'advertisements':
      return t('advertisements.title') + ' • ' + name
    case 'profile':
      return t('advertisements.title') + ' • ' + name
    case 'post':
      return t('post.title') + ' • ' + name
    default:
      if (!title || title.trim().length === 0) {
        return name
      } else {
        return toExcerpt(title, 100) + ' • ' + name
      }
  }
}

export const isDevelopment = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}
