import React, { memo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isFuture } from 'date-fns'
import { formatDateDistance, formatLongDate } from 'app/helpers/datetime'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { t } from 'app/locales'

const Subtitle = ({ post, source }) => {
  const [now, setNow] = useState(new Date())
  const { user } = useSelector(state => state.app)

  useEffect(() => {
    window.setInterval(() => {
      setNow(new Date())
    }, 5000)
  }, [])

  const getSubtitleText = () => {
    try {
      let labels
      switch (source) {
        case 'feed':
          labels = ['sponsored', 'created', 'updated', 'suggested']
          break
        case 'profile':
          labels = ['created', 'updated', 'scheduled']
          break
        case 'post':
          labels = ['sponsored', 'updated', 'created']
          break
        case 'bookmarks':
          labels = ['created', 'updated']
          break
        default:
          labels = ['created']
          break
      }

      const text = []

      if (labels.includes('created')) {
        if (!post.sponsored || !labels.includes('sponsored')) {
          const publishedAt = post.scheduled_at || post.created_at
          if (!isFuture(new Date(publishedAt))) {
            text.push(formatDateDistance(publishedAt, now))
          }
        }
      }

      if (labels.includes('sponsored')) {
        if (post.sponsored) {
          text.push(t('feed.sponsored'))
        }
      }

      if (labels.includes('suggested')) {
        if (
          !post.sponsored &&
          !post.owner.follows &&
          user.id !== post.owner.id
        ) {
          text.push(t('feed.suggested').toLowerCase())
        }
      }

      if (labels.includes('scheduled')) {
        if (isFuture(new Date(post.scheduled_at))) {
          text.push(t('general.scheduled') + formatLongDate(post.scheduled_at))
        }
      }

      if (labels.includes('updated')) {
        if (post.updated && !post.sponsored) {
          text.push(t('feed.updated').toLowerCase())
        }
      }

      return text.join(' / ')
    } catch (error) {
      return null
    }
  }

  return <Text>{getSubtitleText()}</Text>
}

const Text = styled.div`
  font-family: OpenSans;
  font-weight: lighter;
  font-size: 12px;
  color: ${colors.black1};
`

export default memo(Subtitle)
