import pt from './pt-br'
import en from './en-us'
import { store } from '../store'
import get from 'lodash.get'
import { ptBR, enUS } from 'date-fns/locale'

const locales = {
  'en-US': en,
  'pt-BR': pt
}

export const locale = () => {
  const user = store.getState().app.user
  return (user && user.locale) || 'pt-BR'
}

export const timezone = () => {
  const user = store.getState().app.user
  return (user && user.timezone) || 'America/Sao_Paulo'
}

export const language = () => {
  const user = store.getState().app.user
  return (user && user.language) || 'pt'
}

export const t = (path, value) => {
  const translations = locales[locale()]
  let translation = get(translations, path) || ''

  if (typeof value !== 'undefined') {
    if (isNaN(value)) {
      translation = translation(value)
    } else {
      translation =
        value === 1 ? translation.singular(value) : translation.plural(value)
    }
  }

  return translation.length ? translation : `@${path}`
}

export const configLocale = (options = { addSuffix: true }) => {
  switch (locale()) {
    case 'pt':
    case 'pt-BR':
      return { ...options, locale: ptBR }
    case 'en':
    case 'en-US':
      return { ...options, locale: enUS }
    default:
      return { ...options, locale: ptBR }
  }
}

export default locales
