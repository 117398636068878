/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getBadge } from 'app/modules/notifications'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { Link } from 'react-router-dom'
import CardUser from 'app/components/CardUser'
import CardPost from 'app/components/CardPost'
import debounce from 'lodash.debounce'
import { Bell, Home, Search, PlusCircle, User } from 'react-feather'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { getSearch as getSearchPosts } from 'app/modules/posts'
import { getSearch as getSearchUsers } from 'app/modules/users'
import Loading from 'app/components/Loading'
import { t } from 'app/locales'

const Header = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [tabindex, setTabindex] = useState(0)
  const [posts, setPosts] = useState([])
  const [loadingPosts, setLoadingPosts] = useState(false)
  const [users, setUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(false)
  const searchWrapper = useRef(null)
  const debounced = useRef(debounce(value => handleSearch(value), 300))
  const { badge } = useSelector(state => state.notifications)

  useEffect(() => {
    window.setTimeout(async () => {
      await dispatch(getBadge())
    }, 1000)
  }, [])

  useEffect(() => {
    setSearching(true)
    setLoadingPosts(true)
    setLoadingUsers(true)
    if (Boolean(search) && Boolean(search.length)) {
      debounced.current(search)
    }
  }, [search])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleClickOutside = e => {
    if (searchWrapper.current && !searchWrapper.current.contains(e.target)) {
      setSearching(false)
    }
  }

  const handleSearch = search => {
    loadSearchPosts(search, 1)
    loadSearchUsers(search, 1)
  }

  const loadSearchPosts = async (search, page) => {
    const limit = 30
    const skip = limit * (page - 1)
    const data = await dispatch(getSearchPosts({ query: search, skip, limit }))
    setPosts(data)
    setLoadingPosts(false)
  }

  const loadSearchUsers = async (search, page) => {
    const limit = 30
    const skip = limit * (page - 1)
    const data = await dispatch(getSearchUsers({ name: search, skip, limit }))
    setUsers(data)
    setLoadingUsers(false)
  }

  return (
    <Wrapper>
      <Content>
        <LogoContainer>
          <Logo to={'/feed'}>
            <LogoImage
              alt={'Praticantes'}
              src={require('app/assets/img/logo-inline.png')}
            />
          </Logo>
        </LogoContainer>

        <SearchContainer ref={searchWrapper}>
          <SearchInput
            type={'text'}
            value={search}
            placeholder={t('explore.search')}
            onChange={e => setSearch(e.target.value)}
            onFocus={() => setSearching(true)}
          />
          {Boolean(search.length) && Boolean(searching) && (
            <>
              <Arrow />
              <OverArrow />
              <SearchResult>
                <SearchTabs
                  defaultIndex={0}
                  onSelect={index => setTabindex(index)}
                >
                  <SearchTabsMenu>
                    <SearchTabsMenuTab active={tabindex === 0}>
                      <SearchTabsMenuTabText active={tabindex === 0}>
                        {t('explore.posts')}
                      </SearchTabsMenuTabText>
                    </SearchTabsMenuTab>
                    <SearchTabsMenuTab active={tabindex === 1}>
                      <SearchTabsMenuTabText active={tabindex === 1}>
                        {t('explore.users')}
                      </SearchTabsMenuTabText>
                    </SearchTabsMenuTab>
                  </SearchTabsMenu>
                  <TabPanel>
                    <ContentScroll>
                      {Boolean(loadingPosts) && <Loading />}
                      {!Boolean(loadingPosts) && !Boolean(posts.length) && (
                        <Empty>{t('explore.emptyPosts')}</Empty>
                      )}
                      {!Boolean(loadingPosts) &&
                        Boolean(posts.length) &&
                        posts.map(post => (
                          <CardPost
                            key={post.id}
                            post={post}
                            highlights={search.split(' ')}
                          />
                        ))}
                    </ContentScroll>
                  </TabPanel>
                  <TabPanel>
                    <ContentScroll>
                      {Boolean(loadingUsers) && <Loading />}
                      {!Boolean(loadingUsers) && !Boolean(users.length) && (
                        <Empty>{t('explore.emptyUsers')}</Empty>
                      )}
                      {!Boolean(loadingUsers) &&
                        Boolean(users.length) &&
                        users.map(user => (
                          <CardUser key={user.id} user={user} />
                        ))}
                    </ContentScroll>
                  </TabPanel>
                </SearchTabs>
              </SearchResult>
            </>
          )}
        </SearchContainer>

        <MenuContainer>
          <Button to={'/feed'}>
            <Home
              size={20}
              color={
                location.pathname.includes('/feed')
                  ? colors.praticantes3
                  : colors.gray5
              }
            />
            <ButtonText active={location.pathname.includes('/feed')}>
              {t('feed.title')}
            </ButtonText>
          </Button>
          <Button to={'/explore'}>
            <Search
              size={20}
              color={
                location.pathname.includes('/explore')
                  ? colors.praticantes3
                  : colors.gray5
              }
            />
            <ButtonText active={location.pathname.includes('/explore')}>
              {t('explore.title')}
            </ButtonText>
          </Button>
          <Button to={'/publish'}>
            <PlusCircle
              size={20}
              color={
                location.pathname.includes('/publish')
                  ? colors.praticantes3
                  : colors.gray5
              }
            />
            <ButtonText active={location.pathname.includes('/publish')}>
              {t('publish.title')}
            </ButtonText>
          </Button>
          <Button to={'/notifications'}>
            <Bell
              size={20}
              color={
                location.pathname.includes('/notifications')
                  ? colors.praticantes3
                  : colors.gray5
              }
            />
            <ButtonText active={location.pathname.includes('/notifications')}>
              {t('notifications.title')}
            </ButtonText>
            {Boolean(badge) && <Badge count={badge}>{badge}</Badge>}
          </Button>
          <Button to={'/profile'}>
            <User
              size={20}
              color={
                location.pathname.includes('/profile')
                  ? colors.praticantes3
                  : colors.gray5
              }
            />
            <ButtonText active={location.pathname.includes('/profile')}>
              {t('profile.title')}
            </ButtonText>
          </Button>
        </MenuContainer>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: center;
  background: ${colors.white1};
  border-bottom: 1px solid ${colors.gray1};
  z-index: 100;
  cursor: default;
`
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
`
const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`
const SearchContainer = styled.div`
  flex: 1;
  display: flex;
  max-width: 220px;
  position: relative;
  flex-direction: row;
  align-items: center;
`
const SearchInput = styled.input`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  font-family: OpenSans;
  font-weight: regular;
  font-size: 14px;
  color: ${colors.black1};
  border: none;
  background: ${colors.white2};
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  padding: 5px 10px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray2};
  }
`
const SearchResult = styled.div`
  position: absolute;
  top: 48px;
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  left: -100px;
  right: -100px;
  background: ${colors.white1};
  min-height: 30px;
  z-index: 2;
`
const Arrow = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid ${colors.gray1};
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.0975);
  top: 40px;
  left: calc(50% - 7px);
  transform: rotate(45deg);
  position: absolute;
  z-index: 1;
`
const OverArrow = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid ${colors.white1};
  background: ${colors.white1};
  top: 42px;
  left: calc(50% - 7px);
  transform: rotate(45deg);
  position: absolute;
  z-index: 3;
`
const MenuContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
const Logo = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`
const LogoImage = styled.img`
  height: 36px;
`
const Button = styled(Link)`
  position: relative;
  min-width: 56px;
  border-radius: 4px;
  padding: 5px 0;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
const ButtonText = styled.div`
  font-family: OpenSans;
  font-weight: regular;
  color: ${({ active }) => (active ? colors.praticantes3 : colors.gray5)};
  font-size: 11px;
  margin-top: 5px;
`
const Badge = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  min-width: 14px;
  height: 17px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: ${props => (props.count > 9 ? 'normal' : 'bold')};
  color: ${colors.white1};
  background: ${colors.red1};
  border-radius: 50px;
`
const SearchTabs = styled(Tabs)`
  width: 100%;
  flex-direction: row;
`
const SearchTabsMenu = styled(TabList)`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
`
const SearchTabsMenuTab = styled(Tab)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${colors.black3};
  border-color: ${props => (props.active ? colors.gray2 : colors.gray1)};
  cursor: pointer;
`
const SearchTabsMenuTabText = styled.div`
  margin-left: 10px;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 10px;
  color: ${props => (props.active ? colors.black3 : colors.gray2)};
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`
const ContentScroll = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 70vh;
`
const Empty = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 12px;
  color: ${colors.gray2};
  text-align: center;
  padding: 32px;
`

export default memo(Header)
