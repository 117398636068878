export const youtubeStringParser = (string = '') => {
  const match = string.match(
    /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?/ /* eslint-disable-line no-useless-escape*/
  )
  if (match) {
    return {
      url: match[0],
      id: match[5].slice(0, 11)
    }
  }
  return {}
}

export const youtubeGetThumbnail = ({ url, id }) => {
  const video = id || youtubeStringParser(url).id
  return `https://img.youtube.com/vi/${video}/maxresdefault.jpg`
}
