import { locale } from 'app/locales'

export const tags = {
  'en-US': [
    { slug: 'publicacao', label: 'Publication' },
    { slug: 'estudo', label: 'Study' },
    { slug: 'testemunho', label: 'Testimony' },
    { slug: 'oracao', label: 'Prayer' },
    { slug: 'noticia', label: 'News' },
    { slug: 'pregacao', label: 'Sermon' },
    { slug: 'devocional', label: 'Devotional' },
    { slug: 'louvor', label: 'Praise' },
    { slug: 'evento', label: 'Event' },
    { slug: 'necessidade', label: 'Need' },
    { slug: 'reflexao', label: 'Meditation' }
  ],
  'pt-BR': [
    { slug: 'publicacao', label: 'Publicação' },
    { slug: 'estudo', label: 'Estudo' },
    { slug: 'testemunho', label: 'Testemunho' },
    { slug: 'oracao', label: 'Oração' },
    { slug: 'noticia', label: 'Notícia' },
    { slug: 'pregacao', label: 'Pregação' },
    { slug: 'devocional', label: 'Devocional' },
    { slug: 'louvor', label: 'Louvor' },
    { slug: 'evento', label: 'Evento' },
    { slug: 'necessidade', label: 'Necessidade' },
    { slug: 'reflexao', label: 'Meditação' }
  ]
}

export const getTags = () => {
  return tags[locale()]
}
export const getFirstTag = () => {
  return getTags()[0].slug
}
export const getTagLabel = slug => {
  const { label } = getTags().find(item => item.slug === slug) || {}
  return label
}
export const getTagBySlug = slug => {
  const tag = getTags().filter(item => item.slug === slug)
  return tag[0] || {}
}
