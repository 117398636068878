import React, { memo, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import { setUser } from 'app/modules/app'
import AsyncStorage from '@react-native-community/async-storage'
import { getUser, createUser } from 'app/modules/users'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import Spinner from 'app/components/Spinner'
import { FaFacebookF, FaGoogle } from 'react-icons/fa'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { Link } from 'react-router-dom'
import { signInWithEmail } from 'app/helpers/auth'
import { signinWithFacebook } from 'app/helpers/auth'
import { signinWithGoogle } from 'app/helpers/auth'
import { getUserInfos } from 'app/helpers/auth'
import Logs from 'app/helpers/logs'
import { parse, stringify } from 'query-string'
import { t } from 'app/locales'
import { locale } from 'app/locales'
import { timezone } from 'app/locales'

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required()
})

const Signin = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [params, setParams] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (location.pathname === '/signin') {
      const { url } = parse(location.search)
      if (Boolean(url)) {
        setParams({ url })
      }
    } else if (location.pathname !== '/') {
      setParams({ url: location.pathname })
    }
    AsyncStorage.clear()
  }, [location])

  const emailAuth = async values => {
    try {
      setLoading(true)
      await signInWithEmail(values.email, values.password)
      authentication(values, false)
    } catch (error) {
      setLoading(false)
      toast(t('signin.fail'))
    }
  }

  const socialAuth = async provider => {
    try {
      setLoading(true)

      var response
      switch (provider) {
        case 'facebook':
          response = await signinWithFacebook()
          break
        case 'google':
          response = await signinWithGoogle()
          break
        default:
          response = null
      }

      // Get firebase user
      const firebaseUser = getUserInfos(response, provider)

      // Get current user
      const user = await dispatch(getUser(firebaseUser.email))
      const isNewUser = !Boolean(user) || !Boolean(user.id)

      // Create if no exists
      if (isNewUser) {
        firebaseUser.locale = locale()
        firebaseUser.timezone = timezone()
        await dispatch(createUser({ ...firebaseUser, provider }))
      }

      // Check user
      await authentication(firebaseUser, isNewUser)
    } catch (error) {
      setLoading(false)
    }
  }

  const authentication = async ({ email }, newUser) => {
    const user = await dispatch(getUser(email, { me: true }))

    // Init onesignal
    window.OneSignal.push(() => {
      window.OneSignal.setExternalUserId(user.id)
      window.OneSignal.setEmail(user.email)
      window.OneSignal.setSubscription(true)
      window.OneSignal.getNotificationPermission(permission => {
        if (permission === 'default') {
          Logs.track('User Permission Ignored')
        } else if (permission === 'granted') {
          Logs.track('User Permission Granted')
        } else if (permission === 'denied') {
          Logs.track('User Permission Denied')
        }
      })
    })

    await dispatch(setUser(user))

    Logs.alias()
    if (newUser) {
      Logs.track('User Action Signup')
      history.push(`/confession?${stringify(params)}`)
    } else {
      Logs.track('User Action Signin')
      if (Boolean(params.url)) {
        history.push(params.url)
      } else {
        history.push('feed')
      }
    }

    setLoading(false)
  }

  return (
    <LoginWrapper>
      <Helmet title={getTitle('signin')} />
      <LoginContent>
        <Logo>
          <LogoImage
            src={require('app/assets/img/logo.png')}
            alt={'Praticantes'}
          />
        </Logo>
        <Subtitle>{t('signin.subtitle')}</Subtitle>
        <TextLink to={`/signup?${stringify(params)}`}>
          {t('signin.signup')}
        </TextLink>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={values => emailAuth(values)}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <FormGroup>
                <Input
                  type={'text'}
                  name={'email'}
                  error={Boolean(props.errors.email)}
                  placeholder={t('signin.email')}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                  autoComplete={'off'}
                />
                {Boolean(props.errors.email) && (
                  <InputMessage>{t('signin.emailRequired')}</InputMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type={'password'}
                  name={'password'}
                  error={Boolean(props.errors.password)}
                  placeholder={t('signin.password')}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.password}
                  autoComplete={'off'}
                />
                {Boolean(props.errors.password) && (
                  <InputMessage>{t('signin.passwordRequired')}</InputMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Button type={'submit'}>{t('signin.button')}</Button>
              </FormGroup>
              <FormGroup>
                <Forgot to={`/recovery?${stringify(params)}`}>
                  {t('signin.forgot')}
                </Forgot>
              </FormGroup>
              <SocialButtons>
                <SocialButton
                  onClick={() => socialAuth('facebook')}
                  color={colors.facebook}
                >
                  <FaFacebookF size={24} color={colors.white1} />
                </SocialButton>
                <SocialButton
                  onClick={() => socialAuth('google')}
                  color={colors.google}
                >
                  <FaGoogle size={24} color={colors.white1} />
                </SocialButton>
              </SocialButtons>
            </form>
          )}
        </Formik>
      </LoginContent>
      {loading && <Spinner />}
    </LoginWrapper>
  )
}

const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`
const LoginContent = styled.div`
  width: 320px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`
const Logo = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
`
const LogoImage = styled.img`
  width: 100%;
`
const Subtitle = styled.div`
  font-family: OpenSans;
  font-size: 12px;
  color: ${colors.gray2};
  margin-bottom: 15px;
  text-align: center;
  white-space: pre-wrap;
`
const TextLink = styled(Link)`
  font-family: OpenSans;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: ${colors.praticantes1};
  text-decoration: none;
  margin-bottom: 15px;
  display: flex;
  align-self: center;
`
const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`
const Input = styled.input`
  font-family: OpenSans;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  font-size: 14px;
  margin-bottom: 5px;
  height: 42px;
  box-sizing: border-box;
  color: ${colors.gray2};
  background: ${colors.white1};
  border: 1px solid ${props => (props.error ? colors.red1 : colors.gray1)};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => (props.error ? colors.red1 : colors.gray2)};
  }
`
const InputMessage = styled.div`
  font-family: OpenSans;
  font-size: 10px;
  color: ${colors.red1};
  text-align: right;
  margin-bottom: 10px;
`
const Button = styled.button`
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.white1};
  background: ${colors.praticantes1};
  border: 0;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
`
const Forgot = styled(Link)`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  text-decoration: none;
  color: ${colors.gray2};
  border: 0;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
  align-self: flex-end;
`
const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`
const SocialButton = styled.a`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 20px;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  background: ${props => props.color};
`

export default memo(Signin)
