import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export default {
  get: id => {
    return withConfig(config =>
      axios.get(api`/v1/users/${id}`, config).then(({ data }) => data.data)
    )
  },
  create: data => {
    return withConfig(config =>
      axios.post(api`/v1/users`, data, config).then(({ data }) => data.data)
    )
  },
  update: (id, data) => {
    return withConfig(config =>
      axios
        .put(api`/v1/users/${id}`, data, config)
        .then(({ data }) => data.data)
    )
  },
  delete: id => {
    return withConfig(config =>
      axios.delete(api`/v1/users/${id}`, config).then(({ data }) => data.data)
    )
  },
  search: data => {
    return withConfig(config =>
      axios
        .get(api`/v1/users/search${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    )
  },
  posts: (id, data) => {
    return withConfig(config =>
      axios
        .get(api`/v1/users/${id}/posts${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    )
  },
  bookmarks: data => {
    return withConfig(config =>
      axios
        .get(api`/v1/bookmarks${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    )
  },
  followers: (id, data) => {
    return withConfig(config =>
      axios
        .get(api`/v1/users/${id}/followers${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    )
  },
  following: (id, data) => {
    return withConfig(config =>
      axios
        .get(api`/v1/users/${id}/following${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    )
  },
  follow: id => {
    return withConfig(config => {
      return axios
        .put(api`/v1/users/${id}/follow`, null, config)
        .then(({ data }) => data.data)
    })
  },
  unfollow: id => {
    return withConfig(config => {
      return axios
        .put(api`/v1/users/${id}/unfollow`, null, config)
        .then(({ data }) => data.data)
    })
  },
  subscribe: id => {
    return withConfig(config => {
      return axios
        .put(api`/v1/users/${id}/subscribe`, null, config)
        .then(({ data }) => data.data)
    })
  },
  unsubscribe: id => {
    return withConfig(config => {
      return axios
        .put(api`/v1/users/${id}/unsubscribe`, null, config)
        .then(({ data }) => data.data)
    })
  }
}
