import firebase from 'firebase/app'

export const signInWithEmail = async (email, password) => {
  const data = await firebase.auth().signInWithEmailAndPassword(email, password)
  return data
}

export const createUserWithEmail = async (email, password) => {
  const data = await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
  return data
}

export const signinWithFacebook = async () => {
  const provider = new firebase.auth.FacebookAuthProvider()
  provider.addScope('email')
  const data = await firebase.auth().signInWithPopup(provider)
  return data
}

export const signinWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')
  const data = await firebase.auth().signInWithPopup(provider)
  return data
}

export const getUserInfos = (response, provider) => {
  const user = response.user.toJSON()
  const info = response.additionalUserInfo

  // Facebook only
  const sufix = provider === 'facebook' ? '?width=300&height=300' : ''

  const { name = 'Apple', email } = info.profile
  const profile_picture = user.photoURL + sufix

  return { name, email, provider, profile_picture }
}
