/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useState, useEffect } from 'react'
import { getCommentLikes } from 'app/modules/posts'
import { useDispatch } from 'react-redux'
import CardUser from 'app/components/CardUser'
import Loading from 'app/components/Loading'
import styled from 'styled-components'
import { t } from 'app/locales'
import colors from 'app/config/colors'

const CommentLikes = ({ post, comment }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    if (!Boolean(loading) && !Boolean(finish)) {
      const limit = 20
      const skip = limit * (page - 1)
      setLoading(true)
      const data = await dispatch(
        getCommentLikes(post.id, comment.id, { skip, limit })
      )
      setPage(page + 1)
      setLoading(false)
      setUsers([...users, ...data.users])
      if (data && data.users.length < limit) {
        setFinish(true)
      }
    }
  }

  const renderUsers = () => {
    return users.map(user => {
      return <CardUser key={user.id} user={user} />
    })
  }

  const renderEmpty = () => {
    return <Empty>{t('profile.empty')}</Empty>
  }

  return (
    <Users onScroll={load}>
      {Boolean(users.length) && renderUsers()}
      {Boolean(!loading) && Boolean(users.length === 0) && renderEmpty()}
      {Boolean(loading) && <Loading />}
    </Users>
  )
}

const Users = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
`
const Empty = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 12px;
  color: ${colors.gray2};
  text-align: center;
  padding: 32px;
`

export default memo(CommentLikes)
