/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useState, useEffect } from 'react'
import { seeNotifications } from 'app/modules/notifications'
import { getNotifications } from 'app/modules/notifications'
import { useSelector, useDispatch } from 'react-redux'
import Content from 'app/components/Content'
import CardNotification from 'app/components/CardNotification'
import InfiniteScroll from 'react-infinite-scroller'
import Loading from 'app/components/Loading'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import Logs from 'app/helpers/logs'

const Notifications = () => {
  const dispatch = useDispatch()
  const { notifications } = useSelector(state => state.notifications)
  const [finish, setFinish] = useState(false)

  useEffect(() => {
    window.setTimeout(() => {
      load(1)
      dispatch(seeNotifications())
    }, 1000)
  }, [])

  const load = async page => {
    const limit = 10
    const skip = limit * (page - 1)
    Logs.track('User Fetch Notifications #' + (skip / limit + 1))
    const data = await dispatch(getNotifications({ skip, limit }))
    if (data && data.length < limit) {
      setFinish(true)
    }
  }

  return (
    <Content>
      <Helmet title={getTitle('notifications')} />
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={load}
        hasMore={!finish}
        element={NotificationsContainer}
        loader={<Loading key={null} />}
      >
        {Boolean(notifications.length) &&
          notifications.map(notification => (
            <CardNotification
              key={notification.id}
              notification={notification}
            />
          ))}
      </InfiniteScroll>
    </Content>
  )
}

const NotificationsContainer = styled.div`
  flex: 1;
  flex-direction: column;
`

export default memo(Notifications)
