import React, { memo } from 'react'
import styled from 'styled-components'
import { getTagLabel } from 'app/config/tags'
import colors from 'app/config/colors'
import { Link } from 'react-router-dom'

const Tags = ({ tags = [] }) => (
  <Wrapper>
    {tags.map(tag => (
      <Tag key={tag} to={`/explore/${tag}`}>
        #{getTagLabel(tag)}
      </Tag>
    ))}
  </Wrapper>
)

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  padding-bottom: 0;
`
const Tag = styled(Link)`
  font-family: OpenSans;
  font-size: 10px;
  text-transform: lowercase;
  padding: 5px 10px;
  border-radius: 13px;
  margin-right: 10px;
  color: ${colors.black2};
  background: ${colors.gray1};
  border: 1px solid ${colors.gray1};
  cursor: pointer;
  text-decoration: none;
`

export default memo(Tags)
