/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { getExplore } from 'app/modules/posts'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import Content from 'app/components/Content'
import PostExplore from 'app/components/PostExplore'
import InfiniteScroll from 'react-infinite-scroller'
import Loading from 'app/components/Loading'
import styled from 'styled-components'
import colors from 'app/config/colors'
import Masonry from 'react-masonry-component'
import { Link } from 'react-router-dom'
import { getTags, getTagLabel } from 'app/config/tags'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import Logs from 'app/helpers/logs'
import { t } from 'app/locales'

const Explore = () => {
  const dispatch = useDispatch()
  const tags = getTags()
  const { tag } = useParams()
  const { explore } = useSelector(state => state.posts)
  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)

  const [sort, setSort] = useState(
    ['pregacao', 'estudo'].includes(tag) ? 'popularity' : 'date'
  )

  useEffect(() => {
    setLoading(true)
    if (Boolean(tag)) {
      Logs.track('User Fetch Tag ' + getTagLabel(tag))
    }
    window.setTimeout(() => load(1), 1000)
  }, [tag])

  useEffect(() => {
    setLoading(true)
    load(1)
  }, [sort])

  const load = async page => {
    const limit = 10
    const skip = limit * (page - 1)
    Logs.track('User Fetch Tag #' + (skip / limit + 1))
    const posts = await dispatch(getExplore({ skip, limit, tag, sort }))
    setLoading(false)
    if (posts && posts.length < limit) {
      setFinish(true)
    }
  }

  return (
    <Content width={900}>
      <Helmet title={getTitle('explore')} />
      <Tags>
        {Boolean(tags.length) &&
          tags.map(t => (
            <Tag key={t.slug} to={'/explore/' + t.slug} active={t.slug === tag}>
              {t.label}
            </Tag>
          ))}
      </Tags>
      {Boolean(tag) && (
        <Filter>
          <Select value={sort} onChange={e => setSort(e.target.value)}>
            <SelectOption value={'date'}>{t('explore.sortDate')}</SelectOption>
            <SelectOption value={'popularity'}>
              {t('explore.sortPopularity')}
            </SelectOption>
          </Select>
          {Boolean(loading) && <Loading />}
        </Filter>
      )}
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={load}
        hasMore={!finish}
        element={Posts}
        loader={<Loading key={null} />}
      >
        <ContentMasonry>
          {Boolean(explore.length) &&
            explore.map(post => <PostExplore key={post.id} post={post} />)}
        </ContentMasonry>
      </InfiniteScroll>
    </Content>
  )
}

const Posts = styled.div`
  flex: 1;
  flex-direction: column;
`
const ContentMasonry = styled(Masonry)`
  width: 900px;
  margin: -2.5%;
  margin: 0 auto;
`
const Tags = styled.div`
  display: flex;
  flex: 1;
  padding: 0 100px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`
const Tag = styled(Link)`
  flex-direction: row;
  color: ${colors.white1};
  background: ${colors.praticantes2};
  border-radius: 4px;
  padding: 10px 25px;
  margin: 5px;
  font-size: 10px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  text-decoration: ${props => (props.active ? 'underline' : 'none')};
  text-transform: uppercase;
`
const Filter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Select = styled.select`
  width: 200px;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 10px;
  align-self: center;
  background: ${colors.white1};
  border: 1px solid ${colors.gray1};
`
const SelectOption = styled.option`
  flex-direction: row;
  color: ${colors.white1};
  background: ${colors.praticantes1};
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  text-decoration: ${props => (props.active ? 'underline' : 'none')};
  text-transform: uppercase;
`

export default Explore
