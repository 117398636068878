import React, { memo } from 'react'
import { useLocation, useHistory } from 'react-router'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { t } from 'app/locales'

const Confession = () => {
  const history = useHistory()
  const location = useLocation()

  return (
    <ConfessionWrapper>
      <Helmet title={getTitle('confession')} />
      <ConfessionContent>
        <Logo>
          <LogoImage
            src={require('app/assets/img/logo.png')}
            alt={'Praticantes'}
          />
        </Logo>
        <Title>{t('confession.title')}</Title>
        <Text>{t('confession.description')}</Text>
        <Button onClick={() => history.push(`/location${location.search}`)}>
          {t('confession.button')}
        </Button>
      </ConfessionContent>
    </ConfessionWrapper>
  )
}

const ConfessionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`
const ConfessionContent = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`
const Logo = styled.div`
  width: 100px;
  margin: 0 auto;
  margin-bottom: 20px;
`
const LogoImage = styled.img`
  width: 100%;
`
const Title = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 16px;
  color: ${colors.gray2};
  text-align: center;
  white-space: pre-wrap;
`
const Text = styled.div`
  font-family: OpenSans;
  font-size: 14px;
  color: ${colors.gray2};
  padding: 20px;
  text-align: center;
  white-space: pre-wrap;
`
const Button = styled.button`
  height: 42px;
  box-sizing: border-box;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.white1};
  background: ${colors.praticantes1};
  border: 0;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  cursor: pointer;
`

export default memo(Confession)
