import React, { memo } from 'react'
import styled from 'styled-components'
import { User } from 'react-feather'
import colors from 'app/config/colors'
import { Link } from 'react-router-dom'

const UserPhoto = ({ user, size = 40 }) => {
  if (user.profile_picture) {
    return (
      <Photo to={'/profile/' + user.id} size={size}>
        <PhotoImage src={user.profile_picture} />
      </Photo>
    )
  } else {
    return (
      <Photo to={'/profile/' + user.id} size={size}>
        <User
          size={size / 2.5}
          color={colors.gray2}
          style={{ fill: colors.gray2 }}
        />
      </Photo>
    )
  }
}

const Photo = styled(Link)`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 1000px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.gray1};
  background: ${colors.white2};
`
const PhotoImage = styled.img`
  width: 100%;
  height: 100%;
`
export default memo(UserPhoto)
