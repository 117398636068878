/* eslint-disable array-callback-return */

export const getFileExtension = str => `${/(?:\.([^.]+))?$/.exec(str)[1]}`

export const slugifyHashtag = text =>
  text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[#-?!.{}()=*$@]/g, '')

export const clearHashtag = text =>
  text.toString().replace(/[-_?!.{}()=*$@]/g, '')

export const extractHighlights = (text, highlights, phrases) => {
  const resp = []
  text.split(/[.;!?\n]/g).map(phrase => {
    if (resp.length <= phrases) {
      if (phrase.length > 1) {
        if (
          highlights.some(str =>
            phrase.toLowerCase().includes(str.toLowerCase())
          )
        ) {
          resp.push(`${phrase.trim()}...`)
        }
      }
    }
  })
  return resp.join(' ').replace(/[*]/g, '')
}

export const toExcerpt = (str = '', threshold = 200) => {
  const trimmed = str.trim()
  const flattened = trimmed
    .split('\n')
    .filter(item => item.trim())
    .join(' ')
  const sliced = flattened.substr(0, threshold).trim()
  return flattened.length > threshold ? `${sliced}...` : sliced
}
