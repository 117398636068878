/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useState, useEffect } from 'react'
import API from 'app/api/posts'
import { useParams } from 'react-router'
import Content from 'app/components/Content'
import PostExplore from 'app/components/PostExplore'
import InfiniteScroll from 'react-infinite-scroller'
import Loading from 'app/components/Loading'
import styled from 'styled-components'
import colors from 'app/config/colors'
import Masonry from 'react-masonry-component'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import Logs from 'app/helpers/logs'

const Hashtags = () => {
  const { hashtag } = useParams()
  const [posts, setPosts] = useState([])
  const [finish, setFinish] = useState(false)

  useEffect(() => {
    window.setTimeout(() => {
      Logs.track('User Fetch Hashtag ' + hashtag)
      load(1)
    }, 1000)
  }, [])

  const load = async page => {
    const limit = 10
    const skip = limit * (page - 1)
    const data = await API.explore({ skip, limit, hashtag })
    setPosts([...posts, ...data])
    if (data && data.length < limit) {
      setFinish(true)
    }
  }

  return (
    <Content width={900}>
      <Helmet title={getTitle(`#${hashtag}`)} />

      <ContentHashtag>
        <Hashtag>{`#${hashtag}`}</Hashtag>
      </ContentHashtag>

      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={load}
        hasMore={!finish}
        element={Posts}
        loader={<Loading key={null} />}
      >
        <ContentMasonry>
          {Boolean(posts.length) &&
            posts.map(post => <PostExplore key={post.id} post={post} />)}
        </ContentMasonry>
      </InfiniteScroll>
    </Content>
  )
}

const Posts = styled.div`
  flex: 1;
  flex-direction: column;
`
const ContentMasonry = styled(Masonry)`
  width: 900px;
  margin: -2.5%;
  margin: 0 auto;
`
const ContentHashtag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const Hashtag = styled.div`
  color: ${colors.white1};
  font-family: OpenSans;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 10px 25px;
  border-radius: 4px;
  background: ${colors.praticantes1};
`

export default memo(Hashtags)
