import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export default {
  notifications: data => {
    return withConfig(config => {
      return axios
        .get(api`/v1/notifications${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    })
  },
  badge: () => {
    return withConfig(config => {
      return axios
        .get(api`/v1/notifications/badge`, config)
        .then(({ data }) => data.data)
    })
  },
  see: () => {
    return withConfig(config => {
      return axios
        .put(api`/v1/notifications/see`, null, config)
        .then(({ data }) => data.data)
    })
  }
}
