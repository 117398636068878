import React, { memo } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import colors from 'app/config/colors'

const Video = ({ url, width, height }) => (
  <Wrapper>
    <WrapperVideo url={url} width={width} height={height} controls={true} />
  </Wrapper>
)

const Wrapper = styled.div`
  flex: 1;
`
const WrapperVideo = styled(ReactPlayer)`
  background: ${colors.black1};
`

export default memo(Video)
