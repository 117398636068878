/* eslint-disable no-throw-literal */

import AWS from 'aws-sdk'
import { v1 as uuid } from 'uuid'
import config from 'app/config'

const getFileExtension = str =>
  `.${/(?:\.([^.]+))?$/.exec(str)[1]}`.toLowerCase()

export const upload = async file => {
  try {
    const extension = getFileExtension(file.name)

    AWS.config.update({
      region: config.awsRegion,
      accessKeyId: config.awsAccessKeyId,
      secretAccessKey: config.awsSecretAccessKey,
      endpoint: new AWS.Endpoint(config.awsEndpoint)
    })

    const types = {
      audio: ['.mp3', '.wav', '.mp4'],
      video: ['.webm', '.mov', '.mp4'],
      doc: ['.txt', '.docx', '.doc', '.pdf', '.json', '.jpg', '.jpeg', '.png']
    }

    const type = Object.keys(types).find(type =>
      types[type].includes(extension)
    )

    if (!type) {
      throw 'File not supported'
    }

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: 'praticantes',
        Body: file,
        Key: `${type}/${uuid() + extension}`
      }
    })

    const response = await upload.promise()
    return response.Location
  } catch (err) {
    console.log(err)
  }
}
