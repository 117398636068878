/* eslint-disable array-callback-return */

import React, { memo, useState } from 'react'
import styled from 'styled-components'
import colors from 'app/config/colors'
import linkifyStr from 'linkifyjs/string'
import { slugifyHashtag, toExcerpt } from 'app/helpers/text'
import parse from 'html-react-parser'

const Description = ({ description, expanded = false }) => {
  const [expansion, setExpansion] = useState(expanded)

  if (!description.trim().length) {
    return null
  }

  const styles = description => {
    let strings = description.match(/\*[^*]+\*/g)
    if (strings && strings.length) {
      strings.map(text => {
        var replaced = `<b>${text.replace(/\*/g, '')}</b>`
        description = description.replace(text, replaced)
      })
    }
    let hashtags = description.match(/#([0-9A-zÀ-ÖØ-öø-ÿ]+)/g)
    if (hashtags && hashtags.length) {
      hashtags.map(text => {
        var replaced = `<span>${text}</span>`
        replaced = `<a href="/hashtags/${slugifyHashtag(text)}">${replaced}</a>`
        description = description.replace(text, replaced)
      })
    }
    return description
  }

  let descriptionHTML
  if (expansion) {
    descriptionHTML = styles(linkifyStr(description))
  } else {
    descriptionHTML = styles(linkifyStr(toExcerpt(description)))
  }

  return (
    <Wrapper>
      <WrapperDescription>
        {parse(descriptionHTML)}{' '}
        {!expansion && description.length !== toExcerpt(description).length && (
          <More onClick={() => setExpansion(true)}>continuar lendo</More>
        )}
      </WrapperDescription>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
`
const WrapperDescription = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black1};
  margin: 10px;
  white-space: pre-wrap;
  overflow: hidden;
  a,
  span {
    color: ${colors.praticantes1};
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }
`
const More = styled.button`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black3};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`

export default memo(Description)
