import React, { memo } from 'react'
import styled from 'styled-components'
import Header from 'app/components/Header'

const Content = ({ width = 600, children }) => (
  <Wrapper>
    <Header width={width} />
    <BodyWrapper>
      <BodyWrapperContent width={width}>{children}</BodyWrapperContent>
    </BodyWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  margin: 0 auto;
  min-height: 100vh;
`
const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 77px;
`
const BodyWrapperContent = styled.div`
  display: block;
  max-width: ${props => props.width}px;
  padding: 15px 0;
`

export default memo(Content)
