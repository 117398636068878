import React, { memo } from 'react'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { X } from 'react-feather'

const Modal = ({ title, children, onClose }) => (
  <Wrapper>
    <Content>
      <ContentTitle>
        <Title>{title}</Title>
        <Button onClick={onClose}>
          <X size={20} color={colors.black3} />
        </Button>
      </ContentTitle>
      <ContentChildren>{children}</ContentChildren>
    </Content>
    <Opacity onClick={onClose} />
  </Wrapper>
)

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`
const Opacity = styled.a`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.black1};
  border: none;
  opacity: 0.65;
  z-index: 200;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  max-height: 80%;
  background: ${colors.white1};
  border-radius: 12px;
  overflow: hidden;
  z-index: 300;
`
const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  border-bottom: 1px solid ${colors.gray1};
  position: relative;
  background: ${colors.white2};
`
const Title = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.black3};
  text-align: center;
  padding-left: 20px;
`
const Button = styled.button`
  position: absolute;
  right: 10px;
  cursor: pointer;
  border: none;
  background: none;
`
const ContentChildren = styled.div`
  dislay: flex;
  flex-direction: column;
  width: 100%;
`

export default memo(Modal)
