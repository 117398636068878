import React, { memo } from 'react'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { Link } from 'react-router-dom'

const CardAdvertisement = ({ advertisement }) => (
  <Wrapper>
    <Image>
      <ImageThumbnail src={advertisement.image} />
    </Image>
    <Title>{advertisement.title}</Title>
    <Button to={'/advertisements/' + advertisement.id}>Veja mais</Button>
  </Wrapper>
)

const Wrapper = styled.div`
  width: calc((100% / 3) - 18px);
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  background: ${colors.white1};
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  text-decoration: none;
  overflow: hidden;
  margin: 8px;
`
const Image = styled.div`
  width: 100%;
  height: 210px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ImageThumbnail = styled.img`
  width: 100%;
`
const Title = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.black3};
  padding: 10px;
  padding-bottom: 0;
`
const Button = styled(Link)`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${colors.white1};
  text-align: center;
  text-transform: uppercase;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 4px;
  background: ${colors.praticantes1};
  text-decoration: none;
`

export default memo(CardAdvertisement)
