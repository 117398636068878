import firebase from 'firebase/app'
import AsyncStorage from '@react-native-community/async-storage'
import { isFuture } from 'date-fns'
import axios from 'axios'

axios.defaults.withCredentials = true

const config = {
  env: 'production',
  api: {
    develop: 'localhost'
  }
}

const servers = {
  develop: `http://${config.api.develop}:5000/praticantes-27eeb/us-central1/app`,
  production: `https://api.praticantes.org`
}

var api = (path = [], ...params) => {
  const url =
    servers[config.env] +
    path
      .map((item, index) => [item].concat(params[index]))
      .flat()
      .filter(Boolean)
      .join('')
  return url
}

const getConfig = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = firebase.auth().currentUser

      if (user) {
        let token = null
        const storageResult = await AsyncStorage.getItem('token')
        const storageResultObject = JSON.parse(storageResult)

        // Use saved token
        if (
          Boolean(storageResultObject) &&
          Boolean(storageResultObject.expirationTime)
        ) {
          if (isFuture(new Date(storageResultObject.expirationTime))) {
            token = storageResultObject.token
          }
        }

        // Renew token
        if (!Boolean(token)) {
          const result = await user.getIdTokenResult(true)
          await AsyncStorage.setItem('token', JSON.stringify(result))
          token = result.token
        }

        resolve({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
      } else {
        resolve({
          headers: {
            'Content-Type': 'application/json'
          }
        })
      }
    } catch (err) {
      console.log(err)
      reject(err)
    }
  })
}

const withConfig = async request => {
  try {
    const config = await getConfig()

    return request(config)
  } catch (err) {
    console.log(err)
    throw new Error()
  }
}

export { withConfig }
export default api
