import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Signin from 'app/containers/Auth/Signin'
import Signup from 'app/containers/Auth/Signup'
import Terms from 'app/containers/Auth/Terms'
import Confession from 'app/containers/Auth/Confession'
import Location from 'app/containers/Auth/Location'
import Picture from 'app/containers/Auth/Picture'
import Recovery from 'app/containers/Auth/Recovery'
import Feed from 'app/containers/Feed'
import Publish from 'app/containers/Publish'
import Post from 'app/containers/Post'
import Explore from 'app/containers/Explore'
import Tags from 'app/containers/Tags'
import Hashtags from 'app/containers/Hashtags'
import Notifications from 'app/containers/Notifications'
import Profile from 'app/containers/Profile'
import ProfileEdit from 'app/containers/Profile/ProfileEdit'
import Advertisements from 'app/containers/Advertisements'
import Advertisement from 'app/containers/Advertisement'

const ApplicationRoutes = () => (
  <section>
    <Switch>
      <Route path={'/'} exact render={() => <Redirect to={'/feed'} />} />
      <Route path={'/signin'} exact component={Signin} />
      <Route path={'/signup'} exact component={Signup} />
      <Route path={'/terms'} exact component={Terms} />
      <Route path={'/confession'} exact component={Confession} />
      <Route path={'/location'} exact component={Location} />
      <Route path={'/picture'} exact component={Picture} />
      <Route path={'/recovery'} exact component={Recovery} />
      <Route path={'/feed'} exact component={Feed} />
      <Route path={'/explore'} exact component={Explore} />
      <Route path={'/explore/:tag'} exact component={Explore} />
      <Route path={'/posts/:id'} exact component={Post} />
      <Route path={'/hashtags/:hashtag'} exact component={Hashtags} />
      <Route path={'/tags/:tag'} exact component={Tags} />
      <Route path={'/notifications'} exact component={Notifications} />
      <Route path={'/publish'} exact component={Publish} />
      <Route path={'/publish/:id'} exact component={Publish} />
      <Route path={'/advertisements'} exact component={Advertisements} />
      <Route path={'/advertisements/:id'} exact component={Advertisement} />
      <Route path={'/profile'} exact component={Profile} />
      <Route path={'/profile/edit'} exact component={ProfileEdit} />
      <Route path={'/profile/:id'} exact component={Profile} />
    </Switch>
  </section>
)

export default ApplicationRoutes
