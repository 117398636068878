import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Signin from 'app/containers/Auth/Signin'
import Signup from 'app/containers/Auth/Signup'
import Logs from 'app/helpers/logs'

const AuthProvider = ({ component: Component, ...props }) => {
  const location = useLocation()
  const { user } = useSelector(state => state.app)

  if (!user || !user.id) {
    if (location.pathname !== '/recovery' && location.pathname !== '/terms') {
      if (location.pathname === '/signin') {
        Logs.track('View Page SignIn')
        return <Signin {...props} />
      }
      Logs.track('View Page SignUp')
      return <Signup {...props} />
    }
  }

  var page
  if (location.pathname === '/signin') {
    page = 'SignIn'
  } else if (location.pathname === '/signup') {
    page = 'SignUp'
  } else if (location.pathname === '/') {
    page = 'Feed'
  } else if (location.pathname === '/confession') {
    page = 'Confession'
  } else if (location.pathname === '/feed') {
    page = 'Feed'
  } else if (location.pathname === '/explore') {
    page = 'Explore'
  } else if (location.pathname === '/recovery') {
    page = 'Recovery'
  } else if (location.pathname === '/terms') {
    page = 'Terms'
  } else if (location.pathname.includes('posts')) {
    page = 'Post'
  } else if (location.pathname === '/advertisements') {
    page = 'Advertisements'
  } else if (location.pathname.includes('/advertisements/')) {
    page = 'Advertisement'
  } else if (location.pathname.includes('hashtags')) {
    page = 'Hashtag'
  } else if (location.pathname.includes('tags')) {
    page = 'Tag'
  } else if (location.pathname === '/profile') {
    page = 'ProfileMe'
  } else if (location.pathname === '/profile/edit') {
    page = 'ProfileEdit'
  } else if (location.pathname.includes('/profile/')) {
    page = 'Profile'
  }

  if (page && page.length) {
    Logs.track(`View Page ${page}`)
  }

  window.scrollTo(0, 0)
  return <Component {...props} />
}

export default AuthProvider
