import React, { memo, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { likePost, dislikePost } from 'app/modules/posts'
import { bookmarkPost, unbookmarkPost } from 'app/modules/posts'
import { downloadAttachment } from 'app/modules/posts'
import { likeComment, dislikeComment } from 'app/modules/posts'
import { createComment, updateComment, deleteComment } from 'app/modules/posts'
import Modal from 'app/components/Modal'
import User from 'app/components/Post/User'
import Entries from 'app/components/Post/Entries'
import Tags from 'app/components/Post/Tags'
import Description from 'app/components/Post/Description'
import Attachments from 'app/components/Post/Attachments'
import Comments from 'app/components/Post/Comments'
import Totalizers from 'app/components/Post/Totalizers'
import Actions from 'app/components/Post/Actions'
import Likes from 'app/components/Post/Likes'
import CommentLikes from 'app/components/Post/CommentLikes'
import ModalActions from 'app/components/ModalActions'
import ShareButton from 'app/components/ShareButton'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton
} from 'react-share'
import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon
} from 'react-share'
import colors from 'app/config/colors'
import config from 'app/config'
import { t } from 'app/locales'
import Logs from 'app/helpers/logs'

const Post = props => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [post, setPost] = useState(props.post)
  const [comment, setComment] = useState(null)
  const [comments, setComments] = useState(props.comments)
  const [modalLikes, setModalLikes] = useState(false)
  const [modalShare, setModalShare] = useState(false)
  const [modalCommentLikes, setModalCommentLikes] = useState(false)
  const [options, setOptions] = useState([])

  const handleCommentLikes = async comment => {
    setComment(comment)
    setModalCommentLikes(true)
  }

  const handleCommentLike = async comment => {
    if (comment.liked) {
      dispatch(dislikeComment(post.id, comment.id))
      Logs.track('Action Dislike Comment')
    } else {
      dispatch(likeComment(post.id, comment.id))
      Logs.track('Action Like Comment')
    }
    const data = comments.map(c => {
      if (c.id === comment.id) {
        const likes = comment.liked ? comment.likes - 1 : comment.likes + 1
        const liked = comment.liked ? false : true
        return { ...c, likes, liked }
      }
      return c
    })
    setComments(data)
  }

  const handleCommentCreate = async comment => {
    Logs.track('Action Create Comment')
    const data = await dispatch(createComment(post.id, comment))
    setComments([...comments, data])
  }

  const handleCommentUpdate = async (commentId, comment) => {
    Logs.track('Action Update Comment')
    await dispatch(updateComment(commentId, comment))
    const data = comments.map(c => {
      if (c.id === commentId) {
        return { ...c, comment, updated: true }
      }
      return c
    })
    setComments(data)
  }

  const handleCommentDelete = async commentId => {
    Logs.track('Action Delete Comment')
    await dispatch(deleteComment(post.id, commentId))
    const data = comments.filter(c => c.id !== commentId)
    setComments(data)
  }

  const handleDownload = async attachment => {
    window.open(attachment.url, '_blank')
    await dispatch(downloadAttachment(post.id, attachment.id))
  }

  const handleLikeButton = async () => {
    if (post.liked) {
      Logs.track('Action Dislike')
      setPost({ ...post, liked: false, likes: post.likes - 1 })
      await dispatch(dislikePost(post.id))
    } else {
      Logs.track('Action Like')
      setPost({ ...post, liked: true, likes: post.likes + 1 })
      await dispatch(likePost(post.id))
    }
  }

  const handleCommentButton = () => {
    if (!location.pathname.includes('posts')) {
      history.push(`/posts/${post.id}`)
    }
  }

  const handleShareButton = () => {
    const url = `${config.url}/posts/${post.id}`
    const opts = [
      {
        title: (
          <ShareButton
            Button={FacebookShareButton}
            Icon={FacebookIcon}
            title={'Facebook'}
            url={url}
          />
        )
      },
      {
        title: (
          <ShareButton
            Button={TwitterShareButton}
            Icon={TwitterIcon}
            title={'Twitter'}
            url={url}
          />
        )
      },
      {
        title: (
          <ShareButton
            Button={WhatsappShareButton}
            Icon={WhatsappIcon}
            title={'WhatsApp'}
            url={url}
          />
        )
      },
      {
        title: (
          <ShareButton
            Button={LinkedinShareButton}
            Icon={LinkedinIcon}
            title={'LinkedIn'}
            url={url}
          />
        )
      },
      {
        title: (
          <ShareButton
            Button={TelegramShareButton}
            Icon={TelegramIcon}
            title={'Telegram'}
            url={url}
          />
        )
      }
    ]
    setOptions(opts)
    setModalShare(true)
  }

  const handleBookmarkButton = async () => {
    if (post.bookmarked) {
      Logs.track('Action Unbookmark')
      setPost({ ...post, bookmarked: false, bookmarks: post.bookmarks - 1 })
      await dispatch(unbookmarkPost(post.id))
    } else {
      Logs.track('Action Bookmark')
      setPost({ ...post, bookmarked: true, bookmarks: post.bookmarks + 1 })
      await dispatch(bookmarkPost(post.id))
    }
  }

  return (
    <Wrapper>
      {Boolean(post.owner) && <User post={post} source={props.source} />}
      {Boolean(post.entries.length) && <Entries entries={post.entries} />}
      {Boolean(post.tags.length) && <Tags tags={post.tags} />}

      {Boolean(post.description.length) && (
        <Description description={post.description} expanded={props.expanded} />
      )}

      {Boolean(post.attachments.length) && (
        <Attachments
          attachments={post.attachments}
          handleDownload={handleDownload}
        />
      )}

      <Totalizers
        post={post}
        handleLikesButton={() => setModalLikes(true)}
        handleCommentsButton={handleCommentButton}
        handleBookmarksButton={null}
        handleViewsButton={null}
      />

      <Actions
        post={post}
        handleLikeButton={handleLikeButton}
        handleCommentButton={handleCommentButton}
        handleShareButton={handleShareButton}
        handleBookmarkButton={handleBookmarkButton}
      />

      {Boolean(comments) && (
        <Comments
          comments={comments}
          handleCommentLike={handleCommentLike}
          handleCommentLikes={handleCommentLikes}
          handleCommentCreate={handleCommentCreate}
          handleCommentUpdate={handleCommentUpdate}
          handleCommentDelete={handleCommentDelete}
        />
      )}

      {Boolean(modalShare) && (
        <ModalActions options={options} onClose={() => setModalShare(false)} />
      )}

      {Boolean(modalLikes) && (
        <Modal title={t('post.likes')} onClose={() => setModalLikes(false)}>
          <Likes post={post} />
        </Modal>
      )}

      {Boolean(modalCommentLikes) && (
        <Modal
          title={t('post.likes')}
          onClose={() => setModalCommentLikes(false)}
        >
          <CommentLikes post={post} comment={comment} />
        </Modal>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  background: ${colors.white1};
  border: 1px solid ${colors.gray1};
  border-radius: 4px;
  text-decoration: none;
  overflow: hidden;
`

export default memo(Post)
