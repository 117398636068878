import React, { memo, useState } from 'react'
import Video from 'app/components/Video'
import styled from 'styled-components'
import colors from 'app/config/colors'
import Slider from 'react-slick'
import { ArrowLeft, ArrowRight } from 'react-feather'

const Entries = ({ entries = [], size = 600 }) => {
  const [page, setPage] = useState(0)
  const width = window.innerWidth >= size ? size : window.innerWidth

  const getImageComponent = entry => {
    return (
      <Entry key={entry.id}>
        <EntryImage
          src={entry.url}
          width={width}
          height={(width * entry.height) / entry.width}
        />
      </Entry>
    )
  }

  const getVideoComponent = entry => {
    return (
      <Entry key={entry.id}>
        <EntryVideo
          url={entry.url}
          width={width}
          height={(width * 360) / 640}
        />
      </Entry>
    )
  }

  const renderPrevArrow = () => {
    return (
      <Arrow left={10}>
        <ArrowLeft size={26} color={colors.black2} />
      </Arrow>
    )
  }

  const renderNextArrow = () => {
    return (
      <Arrow right={10}>
        <ArrowRight size={26} color={colors.black2} />
      </Arrow>
    )
  }

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    adaptiveHeight: true,
    prevArrow: renderPrevArrow(),
    nextArrow: renderNextArrow(),
    afterChange: index => setPage(index)
  }

  return (
    <Wrapper>
      {entries.length > 1 && (
        <Counter>{`${page + 1} / ${entries.length}`}</Counter>
      )}
      <Slider {...settings}>
        {entries.map(entry => {
          if (entry.type === 'video') {
            return getVideoComponent(entry)
          } else {
            return getImageComponent(entry)
          }
        })}
      </Slider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  position: relative;
`
const Entry = styled.div`
  width: 100%;
  display: flex !important;
`
const EntryImage = styled.img`
  width: 100%;
`
const EntryVideo = styled(Video)`
  width: 100%;
  background: ${colors.black1};
`
const Counter = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0.9;
  padding: 3px 10px;
  border-radius: 20px;
  color: ${colors.white1};
  font-size: 12px;
  letter-spacing: -1px;
  font-family: OpenSans;
  font-weight: normal;
`
const Arrow = styled.div`
  position: absolute;
  top: calc(50% - 30px);
  left: ${props => props.left}px;
  right: ${props => props.right}px;
  z-index: 10;
  width: 30px;
  height: 30px;
  background: ${colors.white1};
  opacity: 0.8;
  border-radius: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export default memo(Entries)
