import { combineReducers } from 'redux'
import app from './app'
import users from './users'
import posts from './posts'
import advertisements from './advertisements'
import notifications from './notifications'

export default combineReducers({
  app,
  users,
  posts,
  advertisements,
  notifications
})
