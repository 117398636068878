/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { getPost } from 'app/modules/posts'
import { toast } from 'react-toastify'
import Content from 'app/components/Content'
import { createPost, updatePost } from 'app/modules/posts'
import UserPhoto from 'app/components/UserPhoto'
import Spinner from 'app/components/Spinner'
import Video from 'app/components/Video'
import Modal from 'app/components/Modal'
import DatePicker from 'react-datepicker'
import { Clock, Paperclip, Image as ImageIcon, XCircle } from 'react-feather'
import { getTags } from 'app/config/tags'
import { formatLongDate } from 'app/helpers/datetime'
import { getFileExtension } from 'app/helpers/text'
import { youtubeStringParser } from 'app/helpers/youtube'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { t } from 'app/locales'
import Logs from 'app/helpers/logs'

const Publish = ({ history }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const tags = getTags()
  const editing = Boolean(id) ? true : false
  const { user } = useSelector(state => state.app)
  const [post, setPost] = useState({
    description: '',
    entries: [],
    attachments: [],
    tags: [],
    scheduled_at: null
  })
  const [modal, setModal] = useState(false)
  const [scheduledAt, setScheduledAt] = useState(null)
  const [loading, setLoading] = useState(editing)
  const [preview, setPreview] = useState(true)
  const inputPhoto = useRef(null)
  const inputFile = useRef(null)
  const width = window.innerWidth >= 600 ? 600 : window.innerWidth

  useEffect(() => {
    window.setTimeout(async () => {
      if (editing) {
        const data = await dispatch(getPost(id))
        setPost(data)
        setScheduledAt(
          Boolean(data.scheduled_at) ? new Date(data.scheduled_at) : null
        )
        setLoading(false)
      }
    }, 1000)
  }, [])

  const setEntryDimensions = ({ naturalWidth, naturalHeight }, entry) => {
    entry.width = naturalWidth
    entry.height = naturalHeight
  }

  const handleTag = tag => {
    if (hasTag(tag)) {
      const updated = post.tags.filter(t => t !== tag)
      setPost({ ...post, tags: updated })
    } else {
      if (post.tags.length < 3) {
        setPost({ ...post, tags: [...post.tags, tag] })
      }
    }
  }

  const handleEntry = files => {
    if (files && files.length) {
      setPost({ ...post, entries: [...post.entries, ...files] })
    }
  }

  const handleAttachment = files => {
    if (files && files.length) {
      setPost({ ...post, attachments: [...post.attachments, ...files] })
    }
  }

  const hasTag = tag => {
    return Boolean(post.tags.filter(t => t === tag).length)
  }

  const validate = post => {
    if (!post.description && !post.entries.length && !post.attachments.length) {
      toast(t('publish.descriptionEmpty'))
      return false
    }
    if (post.tags.length === 0) {
      toast(t('publish.tagsEmpty'))
      return false
    }
    return true
  }

  const handleDescription = description => {
    let url = youtubeStringParser(description).url
    if (post.entries.length === 0 && preview && url) {
      post.entries.push({ provider: 'youtube', type: 'video/youtube', url })
    }
    setPost({ ...post, description })
  }

  const handleRemovePreview = () => {
    setPost({ ...post, entries: [] })
    setPreview(false)
  }

  const handleSave = async () => {
    setLoading(true)
    if (validate(post)) {
      if (editing) {
        post.scheduledAt = Boolean(scheduledAt) ? scheduledAt : null
        const updated = await dispatch(updatePost(id, post))
        if (Boolean(updated)) {
          toast(t('publish.success'))
          Logs.track('Action Update Post')
          history.push('/posts/' + id)
        } else {
          toast(t('publish.fail'))
        }
      } else {
        post.scheduledAt = Boolean(scheduledAt) ? scheduledAt : null
        const created = await dispatch(createPost(post))
        if (Boolean(created) && Boolean(created.id)) {
          toast(t('publish.success'))
          Logs.track('Action Create Post')
          history.push('/feed')
        } else {
          toast(t('publish.fail'))
        }
      }
    }
    setLoading(false)
  }

  const getThumbnail = entry => {
    if (['picture', 'video'].includes(entry.type)) {
      if (entry.type === 'picture') {
        return <Image src={entry.url} />
      } else if (entry.type === 'video') {
        if (entry.provider === 'youtube') {
          return (
            <Video url={entry.url} width={width} height={(width * 360) / 640} />
          )
        } else {
          return (
            <Video url={entry.url} width={width} height={(width * 360) / 640} />
          )
        }
      }
    } else {
      if (entry.type.split('/')[0] === 'image') {
        return (
          <Image
            src={URL.createObjectURL(entry)}
            onLoad={e => setEntryDimensions(e.target, entry)}
          />
        )
      } else if (entry.type.split('/')[0] === 'video') {
        if (entry.type.split('/')[1] === 'youtube') {
          return (
            <Video url={entry.url} width={width} height={(width * 360) / 640} />
          )
        } else {
          return (
            <Video
              url={URL.createObjectURL(entry)}
              width={width}
              height={(width * 360) / 640}
            />
          )
        }
      }
    }
  }

  return (
    <Content>
      <Helmet title={getTitle('publish')} />

      <PublishWrapper>
        <User>
          <UserPhoto user={user} size={60} />
        </User>

        <Description
          value={post.description}
          onChange={e => handleDescription(e.target.value)}
          placeholder={t('publish.description')}
        />

        {Boolean(post.entries) && (
          <Entries>
            {post.entries.map(entry => (
              <Entry key={entry.url}>
                {getThumbnail(entry)}
                {!editing && (
                  <EntryRemove onClick={() => handleRemovePreview()}>
                    <XCircle size={26} color={colors.white1} />
                  </EntryRemove>
                )}
              </Entry>
            ))}
          </Entries>
        )}

        {Boolean(post.attachments.length) && (
          <Attachments>
            {post.attachments.map((attachment, index) => (
              <Attachment key={index}>
                <File>
                  <Paperclip size={30} color={colors.gray2} />
                  <FileName>{attachment.name}</FileName>
                  <FileExtension>
                    {getFileExtension(attachment.name)}
                  </FileExtension>
                </File>
                {!editing && (
                  <AttachmentRemove
                    onClick={() => setPost({ ...post, attachments: [] })}
                  >
                    <XCircle size={20} color={colors.gray1} />
                  </AttachmentRemove>
                )}
              </Attachment>
            ))}
          </Attachments>
        )}

        {(!editing ||
          (editing && !scheduledAt) ||
          Boolean(scheduledAt > new Date())) && (
          <Schedule>
            <Clock color={colors.gray2} size={18} />
            {Boolean(scheduledAt) && (
              <ScheduleContent>
                <ScheduleText>
                  {t('publish.scheduledTo')} {formatLongDate(scheduledAt)}
                </ScheduleText>
                <ScheduleAction
                  color={colors.red2}
                  onClick={() => setScheduledAt(null)}
                >
                  {t('publish.scheduledDelete')}
                </ScheduleAction>
              </ScheduleContent>
            )}
            {!Boolean(scheduledAt) && (
              <ScheduleContent>
                <ScheduleText>{t('publish.scheduledEmpty')}</ScheduleText>
                <ScheduleAction
                  color={colors.praticantes2}
                  onClick={() => setModal(true)}
                >
                  {t('publish.scheduled')}
                </ScheduleAction>
              </ScheduleContent>
            )}
            {Boolean(modal) && (
              <Modal
                title={t('publish.scheduledSelectDate')}
                onClose={() => setModal(false)}
              >
                <ScheduleDatePicker>
                  <DatePicker
                    inline
                    showTimeSelect
                    selected={scheduledAt}
                    onChange={date => setScheduledAt(date)}
                    timeFormat={'p'}
                    dateFormat={'dd/MM/yyy HH:ii'}
                    timeCaption={t('publish.time')}
                    minDate={new Date()}
                    timeIntervals={5}
                  />
                  <ScheduleConfirm onClick={() => setModal(false)}>
                    {t('publish.scheduleConfirm')}
                  </ScheduleConfirm>
                </ScheduleDatePicker>
              </Modal>
            )}
          </Schedule>
        )}

        <Tags>
          {tags.map((tag, index) => (
            <Tag
              key={index}
              onClick={() => handleTag(tag.slug)}
              selected={hasTag(tag.slug)}
            >
              {tag.label}
            </Tag>
          ))}
        </Tags>

        <Actions>
          <ActionsColumn>
            {!editing && (
              <>
                <ActionButton
                  onClick={() => inputPhoto.current.click()}
                  title={t('publish.photo')}
                >
                  <ImageIcon size={24} color={colors.gray2} />
                  <Input
                    onChange={e => handleEntry(e.target.files)}
                    type={'file'}
                    accept={'.png, .jpg, .jpeg'}
                    ref={inputPhoto}
                    multiple
                  />
                </ActionButton>
                <ActionButton
                  onClick={() => inputFile.current.click()}
                  title={t('publish.attachment')}
                >
                  <Paperclip size={20} color={colors.gray2} />
                  <Input
                    onChange={e => handleAttachment(e.target.files)}
                    type={'file'}
                    ref={inputFile}
                    multiple
                  />
                </ActionButton>
              </>
            )}
          </ActionsColumn>
          <ActionsColumn>
            <PublishButton onClick={handleSave}>
              {t('publish.create')}
            </PublishButton>
          </ActionsColumn>
        </Actions>
      </PublishWrapper>
      {loading && <Spinner />}
    </Content>
  )
}

const PublishWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  background: ${colors.white1};
  border: 1px solid ${colors.gray1}
  border-top: 4px solid ${colors.praticantes3};
  border-radius: 4px;
`
const User = styled.div`
  position: absolute;
  left: calc(50% - 21px);
  top: -40px;
`
const Description = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 30px;
  resize: none;
  font-family: OpenSans;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  box-sizing: border-box;
  color: ${colors.black2}
  border: 0;
  background: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray2}
  }
`
const Entries = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`
const Entry = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`
const Image = styled.img`
  width: 100%;
`
const EntryRemove = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`
const Attachments = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
`
const Attachment = styled.div`
  position: relative;
  width: calc((600px - 52px) / 4);
  height: calc((600px - 52px) / 4);
  margin-right: 10px;
  margin-bottom: 10px;
`
const File = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.white2};
  border: 1px solid ${colors.gray3};
  box-sizing: border-box;
  border-radius: 4px;
`
const AttachmentRemove = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`
const FileName = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  padding: 0;
  text-align: center;
  color: ${colors.gray2};
  margin: 10px;
  max-height: 45px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FileExtension = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${colors.gray2};
  text-transform: uppercase;
  line-height: 12px;
`
const Schedule = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-top: 1px solid ${colors.gray1};
`
const ScheduleContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ScheduleText = styled.div`
  font-family: OpenSans;
  font-weight: Regular;
  font-size: 12px;
  color: ${colors.gray2};
  line-height: 12px;
  padding: 0 15px;
`
const ScheduleAction = styled.button`
  width: auto;
  font-family: OpenSans;
  font-weight: Bold;
  font-size: 12px;
  color: ${props => props.color};
  border: 0;
  background: none;
  cursor: pointer;
`
const ScheduleDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`
const ScheduleConfirm = styled.button`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  display: flex;
  padding: 0 15px;
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: ${colors.white1};
  background: ${colors.praticantes1};
  border: 1px solid ${colors.praticantes1};
  cursor: pointer;
`
const Tags = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 5px;
  box-sizing: border-box;
  border-top: 1px solid ${colors.gray1};
  border-left: 0;
  border-right: 0;
`
const Tag = styled.button`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 4px;
  margin: 5px;
  color: ${props => (props.selected ? colors.white1 : colors.gray2)};
  background: ${props =>
    props.selected ? colors.praticantes2 : colors.white2};
  border: 1px solid
    ${props => (props.selected ? colors.praticantes2 : colors.gray3)};
  cursor: pointer;
`
const Actions = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
  border-top: 1px solid ${colors.gray1};
`
const ActionsColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const ActionButton = styled.button`
  flex: 1;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 5px;
  background: ${colors.white2};
  border: 1px solid ${colors.gray3};
  cursor: pointer;
`
const PublishButton = styled.button`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-width: 150px;
  color: ${colors.white1};
  background: ${colors.praticantes1};
  border: 1px solid ${colors.praticantes1};
  cursor: pointer;
`
const Input = styled.input`
  display: none;
`

export default memo(Publish)
