import React, { memo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router'
import { updateUser } from 'app/modules/users'
import Spinner from 'app/components/Spinner'
import firebase from 'firebase/app'
import { Helmet } from 'react-helmet'
import { User } from 'react-feather'
import { getTitle } from 'app/helpers/app'
import { parse } from 'query-string'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { t } from 'app/locales'

const Picture = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { user } = useSelector(state => state.app)
  const input = useRef(null)
  const [loading, setLoading] = useState(false)

  const handleUpload = async files => {
    const file = files[0]
    setLoading(true)

    // Upload image
    await firebase
      .storage()
      .ref('profile_pictures')
      .child(user.id)
      .put(file)
    const url = await firebase
      .storage()
      .ref('profile_pictures')
      .child(user.id)
      .getDownloadURL()

    // Update user data
    await Promise.all([
      firebase
        .auth()
        .currentUser.updateProfile({ displayName: user.name, photoUrl: url }),
      dispatch(updateUser(user.id, { profile_picture: url }))
    ])

    handleContinue()
    setLoading(false)
  }

  const handleContinue = () => {
    const params = parse(location.search)
    if (Boolean(params.url)) {
      history.push(params.url)
    } else {
      history.push('/feed')
    }
  }

  return (
    <PictureWrapper>
      <Helmet title={getTitle('picture')} />
      <PictureContent>
        <Logo>
          <LogoImage src={require('app/assets/img/logo.png')} />
        </Logo>
        <Form>
          <FormGroup>
            <Subtitle>{t('picture.subtitle')}</Subtitle>
          </FormGroup>
          <FormGroup>
            <UploadButton onClick={() => input.current.click()}>
              <User size={40} color={colors.gray2} />
              <UploadInput
                ref={input}
                onChange={e => handleUpload(e.target.files)}
                type={'file'}
                accept={'.png, .jpg, .jpeg'}
              />
            </UploadButton>
          </FormGroup>
          <FormGroup>
            <Button onClick={handleContinue}>{t('picture.skip')}</Button>
          </FormGroup>
        </Form>
      </PictureContent>
      {loading && <Spinner />}
    </PictureWrapper>
  )
}

const PictureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 40px);
`
const PictureContent = styled.div`
  width: 320px;
  justify-content: center;
  padding-bottom: 20px;
`
const Logo = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-bottom: 30px;
`
const LogoImage = styled.img`
  width: 100%;
`
const Subtitle = styled.div`
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  color: ${colors.black1};
  margin-bottom: 25px;
  text-align: center;
`
const Form = styled.div`
  display: flex;
  flex-direction: column;
`
const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`
const UploadButton = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 2px solid ${colors.gray2};
  align-self: center;
  margin-bottom: 25px;
  cursor: pointer;
`
const UploadInput = styled.input`
  display: none;
`
const Button = styled.button`
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.white1};
  background: ${colors.praticantes1};
  border: 0;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
`

export default memo(Picture)
