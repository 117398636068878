import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export default {
  feed: data => {
    return withConfig(config => {
      return axios
        .get(api`/v1/posts/feed${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    })
  },
  explore: data => {
    return withConfig(config => {
      return axios
        .get(api`/v1/posts/explore${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    })
  },
  search: data => {
    return withConfig(config => {
      return axios
        .get(api`/v1/posts/search${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    })
  },
  post: id => {
    return withConfig(config => {
      return axios
        .get(api`/v1/posts/${id}`, config)
        .then(({ data }) => data.data)
    })
  },
  deletePost: id => {
    return withConfig(config => {
      return axios
        .delete(api`/v1/posts/${id}`, config)
        .then(({ data }) => data.data)
    })
  },
  createComment: (id, data) => {
    return withConfig(config => {
      return axios
        .post(api`/v1/posts/${id}/comments`, data, config)
        .then(({ data }) => data.data)
    })
  },
  getCommentLikes: (postId, commentId, data) => {
    return withConfig(config => {
      return axios
        .get(
          api`/v1/posts/${postId}/comments/${commentId}/likes${'?' +
            stringify(data)}`,
          config
        )
        .then(({ data }) => data.data)
    })
  },
  updateComment: (id, data) => {
    return withConfig(config => {
      return axios
        .put(api`/v1/posts/comments/${id}`, data, config)
        .then(({ data }) => data.data)
    })
  },
  likeComment: (postId, commentId) => {
    return withConfig(config => {
      return axios
        .put(api`/v1/posts/${postId}/comments/${commentId}/like`, null, config)
        .then(({ data }) => data.data)
    })
  },
  dislikeComment: (postId, commentId) => {
    return withConfig(config => {
      return axios
        .put(
          api`/v1/posts/${postId}/comments/${commentId}/dislike`,
          null,
          config
        )
        .then(({ data }) => data.data)
    })
  },
  deleteComment: (postId, commentId) => {
    return withConfig(config => {
      return axios
        .delete(api`/v1/posts/${postId}/comments/${commentId}`, config)
        .then(({ data }) => data.data)
    })
  },
  comments: id => {
    return withConfig(config => {
      return axios
        .get(api`/v1/posts/${id}/comments`, config)
        .then(({ data }) => data.data)
    })
  },
  likes: (id, data) => {
    return withConfig(config => {
      return axios
        .get(api`/v1/posts/${id}/likes${'?' + stringify(data)}`, config)
        .then(({ data }) => data.data)
    })
  },
  create: data => {
    return withConfig(config => {
      return axios
        .post(api`/v1/posts`, data, config)
        .then(({ data }) => data.data)
    })
  },
  update: (id, data) => {
    return withConfig(config => {
      return axios
        .put(api`/v1/posts/${id}`, data, config)
        .then(({ data }) => data)
    })
  },
  like: id => {
    return withConfig(config => {
      return axios
        .put(api`/v1/posts/${id}/like`, null, config)
        .then(({ data }) => data.data)
    })
  },
  dislike: id => {
    return withConfig(config => {
      return axios
        .put(api`/v1/posts/${id}/dislike`, null, config)
        .then(({ data }) => data.data)
    })
  },
  bookmark: id => {
    return withConfig(config => {
      return axios
        .post(api`/v1/bookmarks/${id}`, null, config)
        .then(({ data }) => data.data)
    })
  },
  unbookmark: id => {
    return withConfig(config => {
      return axios
        .delete(api`/v1/bookmarks/${id}`, config)
        .then(({ data }) => data.data)
    })
  },
  download: ({ id, attachment }) => {
    return withConfig(config => {
      return axios
        .put(api`/v1/posts/${id}/download/${attachment}`, null, config)
        .then(({ data }) => data.data)
    })
  }
}
