import React, { memo, useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import { Formik } from 'formik'
import * as Yup from 'yup'
import firebase from 'firebase/app'
import { toast } from 'react-toastify'
import Spinner from 'app/components/Spinner'
import { Helmet } from 'react-helmet'
import { getTitle } from 'app/helpers/app'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { t } from 'app/locales'

const validationSchema = Yup.object().shape({
  email: Yup.string().required()
})

const Recovery = () => {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  const recovery = async ({ email }) => {
    try {
      setLoading(true)
      await firebase.auth().sendPasswordResetEmail(email)
      toast(t('recovery.message'))
      history.push(`/signin${location.search}`)
      setLoading(false)
    } catch (error) {
      toast(t('recovery.fail'))
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <RecoveryWrapper>
        <Spinner />
      </RecoveryWrapper>
    )
  }

  return (
    <RecoveryWrapper>
      <Helmet title={getTitle('recovery')} />
      <RecoveryContent>
        <Logo>
          <LogoImage
            src={require('app/assets/img/logo.png')}
            alt={'Praticantes'}
          />
        </Logo>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={values => recovery(values)}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <FormGroup>
                <Title>{t('recovery.subtitle')}</Title>
                <Description>{t('recovery.description')}</Description>
              </FormGroup>
              <FormGroup>
                <Input
                  type={'text'}
                  name={'email'}
                  error={Boolean(props.errors.email)}
                  placeholder={t('recovery.email')}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                />
                {Boolean(props.errors.email) && (
                  <InputMessage>{t('recovery.emailRequired')}</InputMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Button type={'submit'}>{t('recovery.send')}</Button>
              </FormGroup>
            </form>
          )}
        </Formik>
      </RecoveryContent>
    </RecoveryWrapper>
  )
}

const RecoveryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 40px);
`
const RecoveryContent = styled.div`
  width: 320px;
  justify-content: center;
  padding-bottom: 20px;
`
const Logo = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-bottom: 30px;
`
const LogoImage = styled.img`
  width: 100%;
`
const Title = styled.div`
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.black2};
  margin-bottom: 10px;
  text-align: center;
`
const Description = styled.div`
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.black2};
  margin-bottom: 25px;
  text-align: center;
  padding: 0 15px;
`
const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`
const Input = styled.input`
  font-family: OpenSans;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  font-size: 14px;
  margin-bottom: 5px;
  height: 42px;
  box-sizing: border-box;
  color: ${colors.gray2};
  background: ${colors.white1};
  border: 1px solid ${props => (props.error ? colors.red1 : colors.gray1)};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => (props.error ? colors.red1 : colors.gray2)};
  }
`
const InputMessage = styled.div`
  font-family: OpenSans;
  font-size: 10px;
  color: ${colors.red1};
  text-align: right;
  margin-bottom: 10px;
`
const Button = styled.button`
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.white1};
  background: ${colors.praticantes1};
  border: 0;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
`

export default memo(Recovery)
