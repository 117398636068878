import React, { memo } from 'react'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { Heart, Eye } from 'react-feather'
import { t } from 'app/locales'
import { Link } from 'react-router-dom'

const Actions = ({ post, handleLikeButton }) => (
  <Wrapper>
    <ActionButton onClick={handleLikeButton}>
      {Boolean(post.liked) && (
        <Heart size={16} color={colors.red1} style={{ fill: colors.red1 }} />
      )}
      {!Boolean(post.liked) && <Heart size={16} color={colors.black2} />}
      <ActionText>{t('actions.like')}</ActionText>
    </ActionButton>
    <ActionLink to={`/posts/${post.id}`}>
      <Eye size={16} color={colors.black2} />
      <ActionText>{t('feed.views', post.views)}</ActionText>
    </ActionLink>
  </Wrapper>
)

const Wrapper = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  border-top: 1px solid ${colors.gray1};
`
const ActionButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
`
const ActionLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
`
const ActionText = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 12px;
  padding-left: 10px;
  color: ${colors.black2};
`

export default memo(Actions)
