import React, { memo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { followUser, unfollowUser } from 'app/modules/users'
import styled from 'styled-components'
import colors from 'app/config/colors'
import UserPhoto from 'app/components/UserPhoto'
import { Link } from 'react-router-dom'
import { t } from 'app/locales'
import Logs from 'app/helpers/logs'

const CardUser = ({ user }) => {
  const me = useSelector(state => state.app.user)
  const [follows, setFollows] = useState(user.follows)
  const dispatch = useDispatch()

  const handleFollow = async e => {
    e.preventDefault()
    if (follows) {
      setFollows(false)
      Logs.track('Action Unfollow')
      await dispatch(unfollowUser(user.id))
    } else {
      setFollows(true)
      Logs.track('Action Follow')
      await dispatch(followUser(user.id))
    }
  }

  return (
    <Wrapper to={`/profile/${user.id}`}>
      <UserPhoto user={user} />
      <Content>
        <ContentName>{user.name}</ContentName>
        {Boolean(user.city) && Boolean(user.state) && (
          <ContentLocation>{user.city + ' / ' + user.state}</ContentLocation>
        )}
      </Content>
      {me.id !== user.id && (
        <Button active={follows} onClick={handleFollow}>
          {follows ? t('actions.unfollow') : t('actions.follow')}
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: row;
  background: ${colors.white1};
  text-decoration: none;
  overflow: hidden;
  padding: 10px;
  border-bottom: 1px solid ${colors.gray1};
  align-items: center;
`
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
`
const ContentName = styled.div`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.black1};
`
const ContentLocation = styled.div`
  font-family: OpenSans;
  font-weight: lighter;
  font-size: 12px;
  color: ${colors.black1};
`
const Button = styled.button`
  font-family: OpenSans;
  font-weight: bold;
  font-size: 12px;
  color: ${props => (props.active ? colors.gray2 : colors.praticantes1)};
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid
    ${props => (props.active ? colors.gray2 : colors.praticantes1)};
  background: none;
  border-radius: 4px;
  width: 120px;
  height: 30px;
  padding: 5px 10px;
`

export default memo(CardUser)
