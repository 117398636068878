import React, { memo } from 'react'
import UserPhoto from 'app/components/UserPhoto'
import styled from 'styled-components'
import colors from 'app/config/colors'
import { formatDistanceStrict } from 'date-fns'
import { configLocale } from 'app/locales'
import { Link } from 'react-router-dom'

const CardNotification = ({ notification }) => {
  const getLink = notification => {
    if (Boolean(notification) && Boolean(notification.link)) {
      return notification.link.replace('praticantes://', '/')
    }
  }

  return (
    <Wrapper>
      <LinkWrapper flex={null} to={`/profile/${notification.user.id}`}>
        <UserPhoto user={notification.user} size={50} />
      </LinkWrapper>
      <LinkWrapper flex={1} to={getLink(notification)}>
        <Content>
          <Title>{notification.message}</Title>
          <Subtitle>
            {formatDistanceStrict(
              new Date(notification.created_at),
              new Date(),
              configLocale()
            )}
          </Subtitle>
        </Content>
      </LinkWrapper>
      {notification && notification.image && (
        <LinkWrapper flex={null} to={getLink(notification)}>
          <Image>
            <ImageThumbnail src={notification.image} />
          </Image>
        </LinkWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 600px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.gray1};
`
const LinkWrapper = styled(Link)`
  flex: ${props => props.flex};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
  padding: 0;
`
const Title = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: ${colors.black3};
  padding-left: 15px;
  padding-right: 15px;
`
const Subtitle = styled.div`
  font-family: OpenSans;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: ${colors.gray2};
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
`
const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
`
const ImageThumbnail = styled.img`
  width: 100%;
`

export default memo(CardNotification)
