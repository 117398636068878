import Immutable from 'seamless-immutable'
import API from 'app/api/users'

const initialState = Immutable({})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export const getUser = id => async (dispatch, getState) => {
  return API.get(id)
    .then(user => {
      return user
    })
    .catch(err => {
      console.log(err)
    })
}

export const updateUser = (id, data) => async (dispatch, getState) => {
  return API.update(id, data)
    .then(user => {
      return user
    })
    .catch(err => {
      console.log(err)
    })
}

export const deleteUser = id => async (dispatch, getState) => {
  return API.delete(id)
    .then(() => {
      return true
    })
    .catch(err => {
      console.log(err)
    })
}

export const getUserPosts = (id, data) => async () => {
  return API.posts(id, data)
    .then(posts => {
      return posts
    })
    .catch(err => {
      console.log(err)
    })
}

export const getUserBookmarks = data => async () => {
  return API.bookmarks(data)
    .then(posts => {
      return posts
    })
    .catch(err => {
      console.log(err)
    })
}

export const getUserFollowers = (id, data) => async () => {
  return API.followers(id, data)
    .then(users => {
      return users
    })
    .catch(err => {
      console.log(err)
    })
}

export const getUserFollowing = (id, data) => async () => {
  return API.following(id, data)
    .then(users => {
      return users
    })
    .catch(err => {
      console.log(err)
    })
}

export const getSearch = data => async () => {
  return API.search(data)
    .then(users => {
      return users
    })
    .catch(err => {
      console.log(err)
    })
}

export const createUser = user => async (dispatch, getState) => {
  return API.create(user)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const followUser = id => async (dispatch, getState) => {
  return API.follow(id)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const unfollowUser = id => async (dispatch, getState) => {
  return API.unfollow(id)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const subscribeUser = id => async (dispatch, getState) => {
  return API.subscribe(id)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

export const unsubscribeUser = id => async (dispatch, getState) => {
  return API.unsubscribe(id)
    .then(data => {
      return data
    })
    .catch(err => {
      console.log(err)
    })
}
